import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { CloseButton, Container, Modal, Spinner, Placeholder, PlaceholderButton, Card } from 'react-bootstrap';
import { withRouter } from './withRouter';
import { Helmet } from 'react-helmet';
import Help from "../components/Help";

import GameList from "constants/GameList";
import GameAddonList from "constants/GameAddonList";
import styles from './ShopAddonStyles.module.scss'
import Game from './Games/Game';
import GameAddon from './Games/GameAddon';
import AddonModal from './AddonModal/AddonModal';
import { addToCart } from 'actions/cart';
import Reviews from "constants/reviews";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carouselStyles.css";
import Slider from "react-slick";
import StarFull from "images/template/full-star.png";
import StarEmpty from "images/template/empty-star.png";

// Lottie animations
import Lottie from 'react-lottie'
import time from 'animations/time.gif'
import players from 'animations/players.gif'
import age from 'animations/age.gif'

import placeHolderDesktop from 'images/gameStorePreviews/placeholder-desktop.png';
import placeHolderMobile from 'images/gameStorePreviews/placeholder-mobile.png';
import { disable } from 'workbox-navigation-preload';


const howItWorksBlocks = [
    {
        number: 1,
        title: "GET IT ON THE BIG SCEEN",
        "description": "Only one person needs to own the games. They will be 'party leader' and it's their job to get Game.City up on the biggest screen they can. Use Teams, Zoom or Discord if you want to play online. Use a smart TV to open it with a browser or connect up with a laptop and HDMI. Once you have achieved that, simply log in to your account."
    },
    {
        number: 2,
        title: "GATHER SOME FRIENDS",
        description: "Gather some friends, either online or in the same room. Once you have Game.City on the screen and you're logged in, players simply need to scan the QR code to join in, or head to Game.City and join using the Room Code.",
    },
    {
        number: 3,
        title: "PLAY AND HAVE FUN",
        description: "Once joined, choose a potato avatar, change your nickname and then choose a game. Get playing and have a great time. All our online games are extra family friendly so everyone can enjoy!"
    }
];

class ShopAddon extends Component {
    static displayName = ShopAddon.name;

    constructor(props) {
        super(props);

        this.state = {
            showHowToPlay: false,
            isLoading: true,
            baseGame: GameList.find((x) => this.props.addon.forGameId === x.gameId),
        };
    }
    carouselSettings = {
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: true,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        customPaging: function (i) {
            return (
                <div className="carousel-dot">

                </div>
            );
        },
    };


    componentDidMount() {
        window.scrollTo(0, 0);
        console.log("modal", this.ref);
        if (this.props.addon) {
            const images = [
                this.props.addon.meta.storeBackground,
                this.props.addon.meta.storeLogo,
                this.props.addon.meta.previewImages.mobile1,
                this.props.addon.meta.previewImages.desktop1,
                this.props.addon.meta.previewImages.desktop2,
                this.props.addon.meta.previewImages.mobile2,
            ];
            Promise.all(images.map(image => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = resolve;
                    img.onerror = reject;
                    img.src = image;
                });
            })).then(() => {
                setTimeout(() => {
                    this.setState({ isLoading: false });
                }, 100);
            }).catch(error => {
                // if there's an error, we still want to show the app, so set isLoading to false
                this.setState({ isLoading: false });
            });
        } else {
            this.props.navigate("/shop");
        }
    }

    getStoreBackground() {
        return {
            backgroundColor: this.props.addon?.meta.storeBackgroundColour,
            backgroundImage: `url(${this.props.addon?.meta.storeBackground})`,
        }
    }

    getStoreAccentBorder() {
        return {
            backgroundColor: this.props.addon?.meta.storeAccentColour,
            border: `10px solid ${this.props.addon?.meta.storeAccentColour}`
        }
    }

    getStoreAccentBackground() {
        return {
            backgroundColor: this.props.addon?.meta.storeAccentColour,
            border: `10px solid ${this.props.addon?.meta.storeAccentColour}`
        }
    }

    getStoreAccentImageBorder() {
        return {
            border: `2px solid ${this.props.addon?.meta.storeAccentColour}`
        }
    }

    getStoreInfoBackground() {
        return {
            backgroundColor: `${this.props.addon?.meta.storeInfoBackground}`
        }
    }
    goToBaseGame = (e) => {
        e.preventDefault();
        this.props.navigate(this.state.baseGame.storeSlug)
    }

    addGameToCart(game) {
        if (this.shouldShowAddonModal()) this.setState({ showAddonModal : true});
        else this.props.addToCart(game);
    }

    addonModalCallback = ()=>{
        this.setState({ showAddonModal: false });
    }

    shouldShowAddonModal() {
        const ownsBaseGame = !!this.props.auth.user?.games.find(g => g.code === this.state.baseGame.gameId);
        const baseGameIsInCart = !!this.props.cart.items.find(i => i.productId === this.state.baseGame.productId);
        return !(ownsBaseGame || baseGameIsInCart)
    }

    getPrice() {
        //let price = this.props.pricing.prices?.find((x) => x.productId == this.props.addon.productId);
        let countryCode = this.props.pricing.countryCode;

        if (countryCode == "GB") {
            return this.props.addon?.pricing.gb.display;
        } else {
            return this.props.addon?.pricing.us.display;
        }
    }

    closeHelpModal = () => {
        this.setState({ showHowToPlay: false, });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Game City - {this.props.addon?.name}</title>
                    <link rel="canonical" href={`https://game.city/${this.props.addon?.storeSlug}`} />
                    <meta name="description" content={this.props.addon?.description} />
                </Helmet>
                {this.state.showAddonModal && <AddonModal ref={this.ref} addon={this.props.addon} baseGame={this.state.baseGame} callback={this.addonModalCallback} ></AddonModal>}
                <div className={styles.pageShopSingle} style={this.getStoreBackground()}>
                    <section className={`${styles.band}`}>
                        <Container className="text-center">
                            <div className='row'>
                                <div className='offset-xl-1 col-xl-10'>
                                    {/*<div className={styles.backButton}>*/}
                                    {/*    <button onClick={() => this.props.navigate("/shop")} className={`${styles.button} ${styles.alternate} ${styles.small}`} style={{ width: "fit-content" }}><BiLeftArrowAlt /> Back</button>*/}
                                    {/*</div>*/}
                                    <div className={styles.gameLogo}>
                                        <img src={this.props.addon?.meta.storeLogo} className={`${styles.storeLogo} ${this.props.addon?.meta.storeLogo2 && styles.hasSecond}`} alt={`${this.props.addon.name} Logo`} />
                                        {this.props.addon?.meta.storeLogo2 && <img src={this.props.addon?.meta.storeLogo2} className={styles.storeLogo2} alt={`${this.props.addon.name} logo`}></img>}
                                    </div>
                                    {/* {console.log(this.state.game.gameInfo?.minAge)} */}
                                    <div className='row'>
                                        <div className='col-lg-8 order-2 order-lg-1 text-center'>
                                            <div className={`${styles.previewImages} d-none d-lg-block`} style={{ ...this.getStoreAccentBorder(), ...this.getStoreAccentBackground() }}>
                                                <div className={styles.imageRow}>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img className={styles.mobilePreview} src={this.state.isLoading ? placeHolderMobile : this.props.addon.meta?.previewImages?.mobile1}></img>
                                                    </div>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img className={styles.desktopPreview} src={this.state.isLoading ? placeHolderDesktop : this.props.addon.meta?.previewImages?.desktop1}></img>
                                                    </div>
                                                </div>
                                                <div className={styles.imageRow}>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img className={styles.desktopPreview} src={this.state.isLoading ? placeHolderDesktop : this.props.addon.meta?.previewImages?.desktop2}></img>
                                                    </div>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img className={styles.mobilePreview} src={this.state.isLoading ? placeHolderMobile : this.props.addon.meta?.previewImages?.mobile2}></img>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`${styles.previewImages} ${styles.mobile} d-block d-lg-none`} style={this.getStoreAccentBorder()}>
                                                <div className={`${styles.imageRow} ${styles.singleDesktopRow}`}>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img src={this.state.isLoading ? placeHolderDesktop : this.props.addon.meta?.previewImages?.desktop1}></img>
                                                    </div>
                                                </div>
                                                <div className={`${styles.imageRow} ${styles.singleDesktopRow}`}>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img src={this.state.isLoading ? placeHolderDesktop : this.props.addon.meta?.previewImages?.desktop2}></img>
                                                    </div>
                                                </div>
                                                <div className={`${styles.imageRow} ${styles.doubleColRow}`}>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img src={this.state.isLoading ? placeHolderMobile : this.props.addon.meta?.previewImages?.mobile1}></img>
                                                    </div>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img src={this.state.isLoading ? placeHolderMobile : this.props.addon.meta?.previewImages?.mobile2}></img>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-lg-4 order-1 order-lg-2'>
                                            <div className={styles.gameInfoWrapper} style={this.getStoreInfoBackground()}>
                                                <div className={styles.addonBannerWrapper} >
                                                    <div className={styles.addonBanner}>Add-on</div>
                                                </div>
                                                {/*<h3 className={styles.addonHeader}>GAME ADDON</h3>*/}
                                                <p className={styles.gamePrice}><strong>{this.getPrice() || <Spinner animation="border" />}</strong></p>

                                                <button className={styles.button} onClick={() => this.addGameToCart(this.props.addon)} disabled={this.props.addon.price === 0} >{this.state.loading ? <Spinner animation="border" /> : this.props.addon.price === 0 ? "Auto Redeemed" : "Add To Basket"}</button>
                                                {/*<br />*/}
                                                {/*<button className={`${styles.button} ${styles.small} alternate}`} onClick={() => this.setState({ showHowToPlay: true, })}>{this.state.loading ? <Spinner animation="border" /> : "How To Play"}</button>*/}


                                                <ul className={styles.gameInfo}>
                                                    <li>
                                                        <div className={styles.icon}>
                                                            <img src={time} />
                                                        </div>
                                                        <span>{this.props.addon.gameInfo?.avgRoundLengthText}</span>
                                                    </li>
                                                    <li>
                                                        <div className={styles.icon}>
                                                            <img src={players} />
                                                        </div>
                                                        <span>{this.props.addon.gameInfo?.minPlayers}-8 Players</span>
                                                    </li>
                                                    <li>
                                                        <div className={styles.icon}>
                                                            <img src={age} />
                                                        </div>
                                                        <span>
                                                            Age {this.props.addon.gameInfo?.minAge}+
                                                        </span>
                                                    </li>
                                                </ul>

                                                <hr></hr>

                                                <ul className={styles.gameDescription}>
                                                    {this.props.addon.gameInfo?.descriptions.map(description => {
                                                        return (<li>
                                                            <p className={styles.descriptionHeader}>{description.header}</p>
                                                            <p className={styles.descriptionBody}>{description.body}</p>
                                                        </li>)
                                                    })}
                                                </ul>

                                                <div className={styles.requiredGameSection} style={{ "background": `${this.props.addon?.meta.storeAccentColour}` }} >
                                                    <p className={styles.header}>Requires</p>
                                                    <img className={styles.gameCard} src={this.state.baseGame.meta.tinyCard} onClick={ this.goToBaseGame} alt="required"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section className={`${styles.band} ${styles.sm}`} style={this.getStoreAccentBackground()}>

                        <Container>

                            {/*<div className="row text-center my-5" id="how-it-works">*/}
                            {/*    <h2 className='text-uppercase mb-1'>Unsure about how it works?</h2>*/}
                            {/*    <h4 className={`${styles.subTitle} text-uppercase`}>Playing a party game on your TV might sound like magic, but don't worry<br />it's as easy as 1-2-3.</h4>*/}
                            {/*    <div className={`${styles.howItWorksBox} py-3`}>*/}
                            {/*        {*/}
                            {/*            howItWorksBlocks.map((x) => {*/}
                            {/*                return <div className={`${styles.howItWorksBlock} my-3`}>*/}
                            {/*                    <div className={styles.number}>*/}
                            {/*                        <p>{x.number}</p>*/}
                            {/*                    </div>*/}
                            {/*                    <h3 className={styles.title}>{x.title}</h3>*/}
                            {/*                    <p className={styles.description}>{x.description}</p>*/}
                            {/*                </div>*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className='row text-center justify-content-center'>
                                {this.props.addon.upsellIds.length > 0 && <>
                                    <h2 className='text-uppercase'>Others you might like</h2>
                                    {GameAddonList.filter((x) => this.props.addon.upsellIds.includes(x.addonId)).map(g => <div className='col-md-6 col-lg-4 text-center'><GameAddon addon={g} key={g.addonId} /></div>)}
                                    {GameList.filter((x) => this.props.addon.upsellIds.includes(x.gameId)).map(g => <div className='col-md-6 col-lg-4 text-center'><Game game={g} key={g.gameId} /></div>)}
                                </>}
                            </div>
                        </Container>
                    </section>
                    <section className={`${styles.reviewsSection} ${styles.band} ${styles.large}`}>
                        <Slider className={styles.carousel} {...this.carouselSettings}>
                            {
                                Reviews.map((review) => {
                                    return <div className={`${styles.reviewWidth} container p-0`}>
                                        <div className={`${styles.reviewCard} card text-center mx-3 border-0 p-3`}>
                                            <h5 className={`${styles.reviewTitle} card-title`}>{review.name}</h5>
                                            <div className="row d-flex w-50 m-auto">
                                                {Array.from(Array(5), (e, i) => {
                                                    return <div className="col p-1">
                                                        <img className="img-fluid m-auto" style={{}} src={i <= review.stars - 1 ? StarFull : StarEmpty} />
                                                    </div>
                                                })}
                                            </div>
                                            <div className={`${styles.reviewBody} card-body`}>
                                                <p className={`${styles.reviewText} card-text`}>{review.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </Slider>
                    </section>
                </div>
                <Modal show={this.state.showHowToPlay} onHide={this.closeHelpModal} contentClassName={styles.rounded} >
                    <CloseButton style={{ position: "absolute" }} onClick={this.closeHelpModal} />
                    <Help />
                </Modal>

            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pricing: state.pricing,
        cart: state.cart
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (game) => {
            dispatch(addToCart(game))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShopAddon));
