import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { withRouter } from 'components/withRouter';
import styles from './LobbyStyles.module.scss'
import { addToCart } from 'actions/cart';

// Lottie animations
import Lottie from 'react-lottie'
import time from 'animations/time.gif'
import players from 'animations/players.gif'
import age from 'animations/age.gif'

class GameMenu extends Component {
    static displayName = GameMenu.name;

    constructor(props) {
        super(props);

        this.state = {
            // game: {},
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.room.startingGame === true && prevProps.room.startingGame === false) {
            this.props.closeGameMenu();
        }
    }

    getStoreBackground() {
        return {
            backgroundImage: `url(${this.props.game?.meta.storeBackground})`
        }
    }

    getStoreAccentBorder() {
        return {
            backgroundColor: this.props.game?.meta.storeAccentColour,
            border: `10px solid ${this.props.game?.meta.storeAccentColour}`
        }
    }

    getStoreAccentBackground() {
        return {
            backgroundColor: this.props.game?.meta.storeAccentColour,
            border: `10px solid ${this.props.game?.meta.storeAccentColour}`
        }
    }

    getStoreAccentImageBorder() {
        return {
            border: `4px solid ${this.props.game?.meta.storeAccentColour}`
        }
    }

    getStoreInfoBackground() {
        return {
            backgroundColor: `${this.props.game?.meta.storeInfoBackground}`
        }
    }

    addGameToCart(game) {
        this.props.addToCart(game);
    }


    goToGame() {
        if (this.props.room.roomOpen) {
            console.log("Sending StartGame to room with gameId : ", this.props.game.gameId);
            this.props.room.room.send("StartGame", { gameId: this.props.game.gameId });
        }
    }

    playersAllConnected() {
        let allConnected = true;
        this.props.room.players.forEach((x) => {
            if (!x.connected) allConnected = false;
        });
        return allConnected;
    }

    render() {
        return (
            <>
                {
                    !this.props.isMobile &&
                    <div className={`${styles.background}`} />
                }
                <div className={`${styles.gameMenu} ${this.props.isMobile && styles.mobile}`}>
                    {
                        this.props.isMobile &&
                        <div className={`${styles.background}`} />
                    }
                    <div className={styles.menu} style={this.getStoreBackground()}>
                        <img className={styles.gameLogo} src={this.props.game?.meta.storeLogo} alt={`${this.props.game.name} Logo`} />
                        <br />
                        <img src={this.props.game?.meta.previewImages.desktop1} alt="" style={this.getStoreAccentImageBorder()} />
                        <div className={styles.infoWrapper} style={{ backgroundColor: this.props.game?.meta.gameMenuBackgroundColour}}>
                            <div className={styles.gameDescription} style={{ color: this.props.game?.meta.gameMenuTextColour }}>
                                {this.props.game.description}
                            </div>
                            <div className={styles.gameInfo} style={{ color: this.props.game?.meta.gameMenuTextColour }}>
                                <ul className={styles.gameMetrics} >
                                    <li style={{ color: this.props.game?.meta.gameMenuTextColour }}>
                                        <div className={styles.icon}>
                                            <img src={time} alt="time"/>
                                        </div>
                                        <span>{this.props.game.gameInfo?.avgRoundLengthText}</span>
                                    </li>
                                    <li style={{ color: this.props.game?.meta.gameMenuTextColour }}>
                                        <div className={styles.icon}>
                                            <img src={players} alt="players"/>
                                        </div>
                                        <span>{this.props.game.gameInfo?.minPlayers}+ Players</span>
                                    </li>
                                    <li style={{ color: this.props.game?.meta.gameMenuTextColour }}>
                                        <div className={styles.icon}>
                                            <img src={age} alt="age"/>
                                        </div>
                                        <span>
                                            Age {this.props.game.gameInfo?.minAge}+
                                        </span>
                                    </li>
                                </ul>
                                {/*                 
                        <ul className={styles.gameDescription}>
                            {this.props.game.gameInfo?.descriptions.map(description => {
                                return (<li>
                                    <p className={styles.descriptionHeader}>{description.header}</p>
                                    <p className={styles.descriptionBody}>{description.body}</p>
                                </li>)
                            })}
                        </ul> */}
                            </div>
                            {this.props.game.licence &&
                                <div className={styles.gameLicence} style={{ color: this.props.game?.meta.gameMenuTextColour }}>
                                    {this.props.game.licence}
                                </div>
                            }
                        </div>
                        <br />

                        <div className={styles.buttonWrap}>
                            <button onClick={() => this.props.closeGameMenu()} className={`${styles.button} ${styles.alternate}`}>Back</button>
                            <button onClick={() => this.goToGame()} className={styles.button} disabled={this.props.room.players.length < this.props.game.gameInfo.minPlayers || !this.playersAllConnected()}>Start Game</button>

                        </div>
                        {
                            this.props.room.players.length < this.props.game.gameInfo.minPlayers ?
                                <div className={styles.infoWrapper} style={{ backgroundColor: this.props.game?.meta.gameMenuBackgroundColour, marginTop: "1vh", }}>
                                    <p style={{ color: this.props.game?.meta.gameMenuTextColour, fontSize: "2.5vh", fontWeight: "bold", margin: "unset" }}>Not enough players to start!</p>
                                </div>
                                :
                                this.playersAllConnected() === false ?

                                    <div className={styles.infoWrapper} style={{ backgroundColor: this.props.game?.meta.gameMenuBackgroundColour, marginTop: "1vh", }}>
                                        <p style={{ color: this.props.game?.meta.gameMenuTextColour, fontSize: "2.5vh", fontWeight: "bold", margin: "unset" }}>Not all players are connected!</p>
                                    </div>
                                    :
                                    null
                        }
                    </div>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        room: state.room,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (game) => {
            dispatch(addToCart(game))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GameMenu));
