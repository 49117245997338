import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { Container, Row, } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import QRCode from 'qrcode.react';
import styles from "components/Room/PlayerList.module.scss";
import { FaCopy, } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import * as Sentry from '@sentry/react';

import GameCityPic from "images/WelcomeGameCityText.png"
import Player from './Player';


class PlayerList extends Component {
    static displayName = PlayerList.name;

    constructor(props) {
        super(props);

        this.state = {
            players: [],
            collapse: true,
            scrolledPastHeader: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.room.roomOpen && !prevProps.room.roomOpen)) {
            if (this.props.room.isHost) {
                //this.setPlayerListeners();
                this.startLocationChecks();
            }
        }

        if (this.props.room.roomOpen == false && prevProps.room.roomOpen == true) {
            //if (this.props.room.isHost) {
            this.setState({ players: [], })
            //}
        }
    }

    handleScroll = (event) => {
        if (window.scrollY > 100) {
            this.setState({ scrolledPastHeader: true, })
        } else {
            this.setState({ scrolledPastHeader: false, })
        }
    }


    handlePosition = () => {
        if (this.props.isRoku) {
            return 0;
        }
        else if (this.state.scrolledPastHeader) {
            return 0
        } else {
            let pos = 100 - window.scrollY; 

            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const startDate = new Date('2024-11-21T00:00:00');
            const endDate = new Date('2024-12-03T23:59:59');
            // FREE WEEKEND
            if (today >= startDate && today <= endDate) {
                pos = 140 - window.scrollY; 
            }

            return `${pos}px`
        }
    }

    async checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id == player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            }, () => {
                player.onChange = (changes) => {
                    let statePlayers = [...this.state.players];
                    let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                    let statePlayer = { ...statePlayers[pos] };
                    changes.forEach(change => {
                        //console.log(change.field);
                        //console.log(change.value);
                        //console.log(change.previousValue);
                        statePlayer[change.field] = change.value;
                    });
                    statePlayers[pos] = statePlayer;
                    this.setState({ players: statePlayers });
                };
            });
        }
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id !== id), }
        });
    }

    async setPlayerListeners() {
        console.log("setting player listeners");
        this.props.room.room.state.players.onAdd = (player, key) => {
            console.log(player, "has been added at", key);
            this.checkAndAddPlayer(player);

        };
        this.props.room.room.state.players.onRemove = (player, key) => {
            console.log(player, "has been removed at", key);
            player.onChange = (changes) => { };
            this.removePlayer(player.id);
        };
    }

    toggleCollapse = () => {
        this.setState({ collapse: !this.state.collapse });
    }

    copyTextToClipboard = () => {
        console.log("copy to clipboard");
        let text = `https://${window.location.host}/join?qrCode=${this.props.room.roomId}`;
        console.log(text);
        //document.execCommand('copy', true, text);
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(text);
        } else {
            document.execCommand('copy', true, text);
        }
    }

    closeRoom = () => {
        this.props.room.room.leave(true);
        this.props.navigate("/");
    }

    logout = () => {
        //TODO: allow roku users to log out.
    }

    startLocationChecks() {
        if (this.props.room.roomOpen) {
            this.props.room.room.send("location_check", { gameId: "lobby", });

        }
        this.locationCheckInterval = setInterval(() => {
            if (this.props.room.roomOpen) {
                this.props.room.room.send("location_check", { gameId: "lobby", });
            }
        }, 10000);
    }

    render() {
        const shouldCollapse = this.state.collapse && !window.location.href.includes("/lobby") && !window.location.href.includes("/roku");
        return (
            <div key="side-bar" className={`${styles.sideBar} ${shouldCollapse && styles.collapse}`} style={{ display: this.props.room.roomOpen && this.props.room.isHost ? "block" : "none", opacity: this.props.room.roomOpen && this.props.room.isHost ? "1" : "0", height: "100%", width: "47.5vh",  top: this.handlePosition(), }}>
                <Container className={styles.sideBarInner}>
                    <img className={styles.welcomePic} src={GameCityPic} alt="welcome"/>
                    <div className={styles.lobbyInfo}>
                        <div>
                            <p>To join the game head to <strong style={{ color: "#EC0080" }}>Game.City</strong> and use the room code: <strong style={{ color: "#EC0080" }}>{this.props.room.roomId}</strong>
                                {!this.props.isRoku && <button className={`${styles.button} ${styles.small} ${styles.alternate}`} style={{ marginLeft: "10px" }} onClick={this.copyTextToClipboard}><FaCopy /></button>}
                            </p>
                            {!this.props.isRoku && <div className={`text-center`}>
                                <button onClick={() => this.props.navigate("/help")} className={`${styles.button}`}>How To Setup</button>
                            </div>}
                        </div>
                        <div className={`${styles.qrCodeBox}`}>
                            <QRCode style={{ width: "12.5vh", height: "12.5vh"}} value={`https://${window.location.hostname}/join/?qrCode=${this.props.room.roomId}`} />
                        </div>
                    </div>
                    <div className={styles.playersJoined}>
                        <h2>Who's In?</h2>
                        <div className={styles.playersJoinedInner}>
                            <Row>
                                {
                                    this.props.room.players.length > 0 ?
                                    this.props.room.players.map((p, index) => {
                                        return <Player player={p} key={index}/>
                                    })
                                    :
                                    <p>No Taters to boil, mash or stick'in a stew... yet</p>
                                }
                            </Row>
                        </div>
                    </div>
                    <div className={`${styles.buttonGroup}`}>
                        {
                            !this.props.isRoku &&
                            <div className={`text-center`}>
                                <button onClick={this.props.isRoku ? this.logout : this.closeRoom} className={`${styles.button} ${styles.alternate} ${styles.small}`}>{this.props.isRoku ? "Log Out" : "Close Room"}</button>
                            </div>
                        }
                        {
                            !window.location.pathname.includes("lobby") && !this.props.isRoku &&
                            <div className={`text-center`}>
                                <button onClick={() => this.props.navigate("/lobby")} className={`${styles.button} ${styles.small}`}>Return to lobby</button>
                            </div>
                        }
                    </div>
                </Container>
                {
                    !window.location.pathname.includes("lobby") && !this.props.isRoku &&
                    <div className={`${styles.collapseButton} ${shouldCollapse && styles.popout}`} onClick={this.toggleCollapse}>
                        <span className={styles.popoutText}><h4>Lobby</h4></span> <IoIosArrowDown className={`${styles.arrow} ${shouldCollapse && styles.popout}`} />
                    </div>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        room: state.room,
    };
}

export default connect(mapStateToProps, null)(withRouter(PlayerList));
