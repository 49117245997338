import React, { Component } from 'react';
import { withRouter } from '../../withRouter';
import { connect, } from 'react-redux';

import QRLogin from "components/Roku/QRLogin/QRLogin"
import styles from 'components/Roku/Welcome/RokuWelcome.module.scss';
import GameList from "constants/GameList";
//Images
import title from "images/WelcomeGameCityText.png"
import hero1 from "images/rokuHeros/1.png";
import hero2 from "images/rokuHeros/2.png";
import hero3 from "images/rokuHeros/3.png";
import wormyBG from "images/wormy-shadows-bg.png";

const heroImages = [
    hero1,
    hero2,
    hero3,
];

class RokuWelcome extends Component {

    constructor(props) {
        super(props);

        this.state = {
            enabled: !this.props.auth.isLoggedIn,
            show: true,
        };
    }

    close = (QRSessionID) => {
        if (!this.state.show) return;
        this.setState({ show: false });
        setTimeout(() => {
            this.setState({ enabled: false });
            this.props.onLogin(QRSessionID);
        }, 600);
    }

    GetGameCards() {
        const games = GameList.filter(g=>g.enabled).map(g => {
            return <div className={styles.game} key={g.gameId}>
                <img src={g.meta.tinyCard} alt={g.name} />
            </div>
        })

        return games;
    }

    render() {
        return <React.Fragment>
            {this.state.enabled &&
                <div className={`${styles.screenOverlay} ${this.state.show && styles.show} ${this.props.styles}`} >
                    <div className={styles.screenOverlayBG}></div>
                    <div className={styles.modalContainer} >
                        <div className={styles.modal} >
                            
                            <div className={styles.heroContainer} >
                                <div className={styles.fade} ></div>
                                <img src={heroImages[0]} alt="hero"/>
                            </div>
                            <div className={styles.detailsContainer} >
                                <img className={styles.wormyBG} src={wormyBG} alt="background" />
                                <div className={styles.detailsZWrapper} >
                                    <img className={styles.titleImage} src={title} alt="title"/>
                            
                                    <h1 className={styles.heading}>Party games on your TV.<br/> Use your phone as the controller!
                                        {/*<b className={styles.overlay}>Party games on your TV.<br/> Use your phone as the controller!</b>*/}
                                    </h1>

                                    <div className={styles.midSection}>
                                        <div className={styles.QRContainer}>
                                            <QRLogin onLogin={this.close} expiredMessage="your session has expired please restart the app." ></QRLogin>
                                        </div>

                                        <div className={styles.callouts} >
                                            <div className={styles.center}>
                                                <h4>Just <b>scan</b> the <b>QR code</b> on your <b>phone</b> to log in and start playing.
                                                   {/* <b className={styles.overlay}>- Just <b>scan</b> the <b>QR code</b> on your <b>phone</b> to log in and start playing.</b>*/}
                                                </h4>
                                                <h4>Don't have an account? don't worry, scan the QR code to get set up.
                                                    {/*<b className={styles.overlay}>- Don't have an account? don't worry, scan the QR code to get set up.</b>*/}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className={styles.gamesCarouselContainer}>
                                        <div className={styles.fade}> </div>
                                        <div className={styles.corouselMask}>
                                            <div className={styles.gamesCarousel}>
                                                {this.GetGameCards()}
                                                {this.GetGameCards()}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pricing: state.pricing,
    };
}

export default connect(mapStateToProps)(withRouter(RokuWelcome));