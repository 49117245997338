import React, { Component } from 'react';
import { withRouter } from '../../withRouter';
import { Spinner, } from 'react-bootstrap';
import { connect, } from 'react-redux';

import styles from 'components/Roku/QRLogin/QRLogin.module.scss';
import QRCode from 'qrcode.react';
import { requestQRSession, getQRSession } from 'actions/auth';

class QRLogin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sessionID: null,
            expires: null,
            expired: false,
            pollCount: 0,
        };
    }

    componentWillMount() {
        this.generateQRSession();
    }

    componentWillUnmount() {
        clearInterval(this.state.pollQRSessionInterval);
    }

    generateQRSession = () => {
        clearInterval(this.state.pollQRSessionInterval);
        if (this.props.auth.isLoggedIn) return;
        this.setState({sessionID: null, expired:false})
        const oldSessionData = JSON.parse(localStorage.getItem("QRSessionData"));
        this.tryRequestQRSession(oldSessionData);
    }

    tryRequestQRSession = (oldSessionData, retryCount = 10) => {
        console.log("Requesting QR Login Session, retry count: ", retryCount);
        this.props.requestQRSession(oldSessionData?.sessionID)
            .then((res) => {
                if(res.status === "Error") throw res.message;
                console.log(res);
                this.updateExpiresIn(new Date(res.expires));
                this.setState({ sessionID: res.sessionID, expired: false });
                const pollQRSessionInterval = setInterval(this.pollForQRAuth, 1000); // Poll every second for the first 60 seconds
                this.setState({ pollQRSessionInterval })
            }).catch(async (err) => {
                console.log("rejected", err);
                this.setState({ errorMessage: err });
                if (retryCount > 0) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    this.tryRequestQRSession(oldSessionData, retryCount - 1);
                }
                else {
                    this.setState({ expired: true });
                    this.updateExpiresIn(new Date());
                }
            });
    }

    pollForQRAuth = () => {
        this.setState({ pollCount: this.state.pollCount + 1 });
        this.updateExpiresIn(this.state.expires);
        if (this.state.pollCount > 60 && this.state.pollCount % 5 !== 0) return; // Poll every 5 seconds after 60 seconds

        this.props.getQRSession(this.state.sessionID)
            .then((res) => {
                const expires = new Date(res.expires)
                this.setState({ expires });
                if (res.user) {
                    clearInterval(this.state.pollQRSessionInterval);
                    console.log("Session Authorized", res.user.token);
                    this.props.onLogin(this.state.sessionID);
                } else if (res.error) {
                    this.handlePollError(res.error);
                }
            })
            .catch((err) => {
                this.handlePollError(err);
            });
    }

    handlePollError = (err) => {
        clearInterval(this.state.pollQRSessionInterval);
        console.log("Session Expired", err);
        localStorage.removeItem("QRSessionData");
        this.setState({ sessionID: null, errorMessage: "Your session has expired please restart the app.", expired: true });
        this.updateExpiresIn(new Date());
    }

    updateExpiresIn = (expires) => {
        const timeLeft = new Date(expires - new Date());
        this.setState({ expires, sessionExpiresIn: timeLeft })
    }

    getExpiresInString = () => {
        if (!this.state.sessionExpiresIn) return "-";
        var minuets = this.state.sessionExpiresIn.getMinutes();
        var seconds = this.state.sessionExpiresIn.getSeconds();
        minuets = isNaN(minuets) ? 0 : minuets;
        seconds = isNaN(seconds) ? 0 : seconds;
        if (minuets <= 0 && seconds <= 0) return "Expired";
        return `${minuets}m ${seconds}s`; 
    }

    render() {
        return <React.Fragment>
            <div className={styles.QRContainer}>
                {
                    this.state.sessionID ?
                        <QRCode
                            size={256}
                            style={{ width: "min(20vw, 20vh)", height: "min(20vw, 20vh)" }}
                            bgColor="transparent"
                            fgColor="white"
                            className={styles.QR}
                            value={`https://${window.location.hostname}/login/?QRSessionID=${this.state.sessionID}`} />
                        :
                        <div style={{ width: "min(20vw, 20vh)", height: "min(20vw, 20vh)" }} >
                            <div className={styles.center} >
                                {this.state.expired ?
                                    this.props.expiredMessage ? <h4>{this.state.errorMessage}</h4> : <button tabIndex={1} className={`${styles.button} ${styles.alternate}`} onClick={() => this.generateQRSession()}>Retry</button>
                                    :
                                    <Spinner animation="border" />}
                            </div>
                        </div>
                }
                {
                    /*window.location.hostname === "localhost"*/true ?
                        <a href={`https://${window.location.hostname}/login/?QRSessionID=${this.state.sessionID}`}>
                            <h3 className={styles.scanMe}>SCAN ME</h3>
                        </a> :
                        <h3 className={styles.scanMe}>SCAN ME</h3>
                }
                <h4 className={styles.expires}>Expires In: {this.getExpiresInString()}</h4>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pricing: state.pricing,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestQRSession: (oldSessionID) => {
            return dispatch(requestQRSession(oldSessionID));
        },
        getQRSession: (QRSessionID) => {
            return dispatch(getQRSession(QRSessionID));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QRLogin));