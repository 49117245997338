import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { withRouter } from '../withRouter';
import styles from 'components/Room/RedirectListener.module.scss';


import hourGlass from 'images/hourglass.png'


class RedirectListener extends Component {
    static displayName = RedirectListener.name;

    constructor(props) {
        super(props);

        this.state = {
           doRedirect: false,
        };
    }

    componentDidMount() {
       
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props.room.startingGame === true && prevProps.room.startingGame === false) {
            console.log("getting user games");
            setTimeout(() => {
                this.setState({ doRedirect: true, });
                this.props.room.room.leave(false);
            }, 1500);
        }
    }

    getGameId = () => {
        return this.props.room.redirectInfo.gameId
    }

    getGameBackground = (id) => {
        switch (id) {
            case 'scrawl':
                return styles.scrawl;
            case 'herd_mentality':
                return styles.herdMentality;
            case 'can_of_squirms':
                return styles.canOfSquirms;
            case 'draw_with':
                return styles.drawAlongWithDave;
            case 'chameleon':
                return styles.theChameleon;
            case 'colour_brain':
                return styles.colourBrain;
            case 'pub_quiz':
                return styles.pubQuiz;
            case 'anagram_magic':
                return styles.anagramMagic;
            case 'maths_master':
                return styles.mathsMaster;
            case 'word_wheel':
                return styles.wordWheel;
            case 'cant_say_umm':
                return styles.cantSayUmm;
            case 'catchphrase':
                return styles.catchphrase;
            default:
                return null;
        }

    }

    getGameTextColor = (id) => {
        switch (id) {
            case 'scrawl':
                return '#EC0080';
            case 'herd_mentality':
                return '#E56DA6';
            case 'can_of_squirms':
                return '#212B3D';
            case 'draw_with':
                return '#FFFFFF';
            case 'chameleon':
                return '#F7EE69';
            case 'colour_brain':
                return '#10232B';
            case 'pub_quiz':
                return '#FFFFFF';
            case 'anagram_magic':
                return '#002026';
            case 'maths_master':
                return '#002026';
            case 'word_wheel':
                return '#002026';
            case 'catchphrase':
                return '#1e236e';
            default:
                return '#FFFFFF';
        }
    }

    render() {
        const startingGame = this.props.room.startingGame;
        const gameURL = this.props.room.redirectInfo.gameURL;
        const isHost = this.props.room.redirectInfo.isHost;
        const sessionId = this.props.room.redirectInfo.sessionId;
        const roomId = this.props.room.redirectInfo.roomId;
        const token = this.props.room.redirectInfo.reconnectionToken;

        let redirectURL = gameURL;
        if (!isHost) {
            redirectURL += "/client"
        }
        redirectURL += `?token=${token}`;

        if (startingGame && this.state.doRedirect) {
            window.location = redirectURL;
        }

        return (
            <div className={`${styles.startingGameWrapper} ${this.getGameBackground(this.getGameId())} ${startingGame && styles.show}`}>
                <div className={styles.startingGameInner}>
                    <img src={hourGlass} alt="hour-galss"></img>
                    <h1 style={{ color : this.getGameTextColor(this.getGameId())}}>Starting Game</h1>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        room: state.room,
    };
}

export default connect(mapStateToProps, null)(withRouter(RedirectListener));
