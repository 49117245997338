import React, { Component } from 'react';
import { withRouter } from '../../withRouter';
import { connect, } from 'react-redux';

import styles from 'components/Roku/AutoJoinModal/AutoJoinModalStyles.module.scss';
import { Spinner } from 'react-bootstrap';
import { getLatestOpenRoom } from 'actions/auth';

class AutoJoinModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            enabled: true,
            show: true,
            timeout: 20, //time = this * the interval so currently 20 * 1000ms = 20s
        };
    }

    componentDidMount() {
        console.log("mount")
        const pollForRoomIDInterval = setInterval(this.pollForRoomID, 1000);
        this.setState({ pollForRoomIDInterval })
    }

    pollForRoomID = () => {
        const timeout = this.state.timeout - 1;
        this.setState({ timeout: timeout })
        console.log(this.state.timeout);
        if (this.state.timeout <= 0) {
            clearInterval(this.state.pollForRoomIDInterval);
            this.setState({ timedOut: true });
        }
        this.props.getLatestOpenRoom(this.props.QRSessionID)
            .then((res) => {
                console.log(res)
                if (res.roomID) {
                    clearInterval(this.state.pollForRoomIDInterval);
                    console.log("Room ID found", res.roomID);
                    this.props.onComplete(res.roomID);
                } 
            })
            .catch((err) => {
                
            });
    }

    onChangeEmail = (e) => {
        const email = e.target.value;
        this.setState({ email });
    };

    onChangeDOB = (e) => {
        const DOB = e.target.value;
        this.setState({ DOB });
    }

    cancel = () => {
        clearInterval(this.state.pollForRoomIDInterval);
        this.close();
    }

    close = () => {
        if (!this.state.show) return;
        this.setState({ show: false });
        setTimeout(() => {
            this.setState({ enabled: false });
        }, 500);
    }

    render() {
        return <React.Fragment>
            {this.state.enabled && window.location.pathname !== "roku" &&
                <div className={`${styles.screenOverlay} ${this.state.show && styles.show} ${this.props.styles}`} >
                    <div className={styles.screenOverlayBG}></div>
                    <div className={styles.modalContainer} >
                        <div className={styles.modal}>
                            <h1>Auto Joining</h1>
                            {this.state.timedOut ? <h3 className={styles.failedToJoin} >Failed to Join</h3> : <><Spinner animation="border" /> <p>{this.state.timeout}s</p></>}
                            <p>{this.state.timedOut ? "We were unable to auto join you to the lobby, please clsoe this message and try manually joining using the room code" : "Please wait while we log you into your Roku device and join you to the lobby"}</p>
                            <div className={styles.buttonWrapper} >
                                <button className={`${styles.button}  ${styles.alternate}`} onClick={this.cancel} >{this.state.timedOut ? "Close" : "Cancel"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pricing: state.pricing,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLatestOpenRoom: (userID) => {
            return dispatch(getLatestOpenRoom(userID));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AutoJoinModal));