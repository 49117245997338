const reviews = [
    {
        name: "Tessa G.",
        stars: 5,
        description: "7 of us haven't laughed as much in years. It was so much fun. I used my I pad and mirrored it to the TV then we all used our phones to draw. Can not recommend this enough for family fun."
    },
    {
        name: "Emma C.",
        stars: 5,
        description: "As a family we love these games. It's so hard getting our teenagers to come out of their rooms and spend time with us but these work! We are a family of 6, ranging from 10 to 42 and all the games allow everyone to get involved.Always a lot of fun and laughter."
    },
    {
        name: "David O.",
        stars: 5,
        description: "Brilliant! The whole family love the variety of games as there's something for everyone."
    },
    {
        name: "Kirsty P.",
        stars: 4,
        description: "A great night of entertainment for all ages! We bought some friends one of the packs and they love it too! We enjoy the giggles and chat too much!"
    },
    {
        name: "Andrew R.",
        stars: 5,
        description: "All the games are incredibly fun, lots of variety."
    },
    {
        name: "Louise D.",
        stars: 4,
        description: "All the family (8-75) enjoy the game city games! We all have a different favourite."
    },
    {
        name: "India M.",
        stars: 5,
        description: "plssss release more games to add on :) we love it"
    },
    {
        name: "Paul M.",
        stars: 5,
        description: "Really enjoyed playing games with the family. Everyone in their comfy chairs as opposed to sitting at the table when playing traditional games. Good fun."
    },
    {
        name: "Sian T.",
        stars: 5,
        description: "Great fun family game suited to a mix of ages. We have had some great game nights together with our children creating lots of laughs and plenty of suspicious thoughts on chameleon! Would highly recommend to others to have some amusing family time together!"
    },
    {
        name: "Kathryn C.",
        stars: 5,
        description: "We love our games nights. These games are entertaining enough to keep everyone engaged."
    },
    {
        name: "Nicola M.",
        stars: 5,
        description: "Great family fun! Our children prefer this to traditional board games and they are a great way to spend family time. We play this with children from aged 8 to granny aged 80. We bought a pack before Xmas last year and the kids still ask for it on so well worth the money. Everyone gets involved"
    },
    {
        name: "Leah R.",
        stars: 4,
        description: "Really fun enjoying games with people virtually as well as in the room. Scrawl is the best!"
    },
    {
        name: "Maja V.",
        stars: 5,
        description: "Great fun for everyone. Everyone loves to play even my 80 year old grandparents!"
    },
    {
        name: "Christina M.",
        stars: 5,
        description: "Cracking family game fun and utter nonsense. Easy for all ages and abilities. Highly recommend!"
    },
    {
        name: "Marybeth D.",
        stars: 5,
        description: "Great games all the family can enjoy. Lots of fun and laughter had by all."
    },
    {
        name: "Samantha L.",
        stars: 5,
        description: "We absolutely love this game. We play it whenever possible and have also introduced to our family online quiz nights with my sister who lives in Germany."
    },
];

export default reviews;