import React, { Component, useState } from 'react';
import { connect, } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { autoLoginQRSession, login } from 'actions/auth';
import { withRouter } from '../withRouter';
import styles from '../Forms/FormStyles.module.scss'
import { Link, createSearchParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            loading: false,
            rememberMe: false,
            showMainNav: false,
            errorMessage: "",
        };
    }

    async componentWillMount() {
        if (this.props.auth.isLoggedIn) {
            const QRSessionID = new URLSearchParams(window.location.search).get("QRSessionID");
            if (QRSessionID) {
                const UserID = this.props.auth.user.id;
                this.setState({loading: true});
                await this.props.autoLoginQRSession(QRSessionID, UserID);
                this.setState({ loading: true });
                return setTimeout(() => this.props.navigate(`/join/?QRSessionID=${QRSessionID}`));
            }
            return setTimeout(() => this.props.navigate("/"));
        } else {
            const rememberMe = localStorage.getItem("rememberMe");
            const email = localStorage.getItem("email");

            if (rememberMe === true) {
                this.setState({ rememberMe, email });
            }
        }
    }


    onChangeEmail = (e) => {
        const email = e.target.value;

        this.setState({ email });
    };

    onChangePassword = (e) => {
        const password = e.target.value;

        this.setState({ password });
    };

    handleLogin = (e) => {
        e.preventDefault();

        if (!this.state.loading) {
            this.setState({ loading: true, errorMessage: "" });

            const email = this.state.email;
            const password = this.state.password;

            if (this.state.rememberMe) {
                localStorage.setItem('rememberMe', this.state.rememberMe);
                localStorage.setItem('email', email);
            }

            const QRSessionID = new URLSearchParams(window.location.search).get("QRSessionID"); //Check if there is a QRSessionID to be authenticated.

            // May need to add additional error checking, so we can provide feedback on input, for example, password not long enough, this applies more on the register form than this one
            this.props.login(email, password, QRSessionID)
                .then((res) => {
                    console.log(res);
                    if (this.props.modal) {
                        this.props.closeModal(true);
                    } else {
                        const returnURL = new URLSearchParams(window.location.search).get("returnURL");
                        console.log("return url : ", returnURL);
                        if (returnURL) {
                            this.props.navigate(`/${returnURL}`);
                        } else {
                            if (QRSessionID) this.props.navigate(`/join/?QRSessionID=${QRSessionID}`);
                            else this.props.navigate("/");
                        }
                    }
                    
                    //http.get(process.env.REACT_APP_API_URL + "api/Authenticate/CheckVoucherExpires").then((response) => {
                    //if (response.data.status === "Success") {
                    //    this.props.history.push({
                    //        pathname: "/authentication/voucher-code"
                    //    });
                    //} else {
                    //    this.props.history.push({
                    //        pathname: "/packs"
                    //    });
                    //}
                    //    console.log(response);
                    //});
                })
                .catch((err) => {
                    this.setState({ loading: false, errorMessage: err });
                });
        }
    };

    goToRegister = () => {
        const QRSessionID = new URLSearchParams(window.location.search).get("QRSessionID");
        if (QRSessionID) this.props.navigate(`/register/?QRSessionID=${QRSessionID}`);
        else this.props.navigate("/register");
    }

    render() {
        return (
            <Form onSubmit={this.handleLogin}>

                <h2 className={styles.formTitle}>Login</h2>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" required onChange={this.onChangeEmail} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" required onChange={this.onChangePassword} />
                </Form.Group>
                {
                    this.state.errorMessage.length > 0 &&
                    <Alert variant={"danger"}>
                            {this.state.errorMessage}
                    </Alert>
                }
                <Link to={"/forgot-password"} className={`${styles.passwordForget}`}>
                    Forgot your password?
                </Link>

                <div className={`${styles.buttonsWrapper} mt-3`}>
                    <button className={`${styles.button} ${styles.alternate}`} type="button" onClick={() => this.goToRegister()} style={{marginRight: '10px'}}>Register</button>
                    <button className={styles.button} type="submit">{this.state.loading ? <Spinner animation="border" /> : "Login"}</button>
                </div>
                    
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password, QRSessionID) => {
            return dispatch(login(email, password, QRSessionID))
        },
        autoLoginQRSession: (QRSessionID, UserID) => {
            return dispatch(autoLoginQRSession(QRSessionID, UserID))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
