import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_GAMES,
    QR_SESSION_REQUEST,
    QR_SESSION_REQUEST_FAILED,
    QR_SESSION_CHECK,
    QR_SESSION_CHECK_FAIL,
    QR_SESSION_LOGIN,
    QR_SESSION_LOGIN_FAIL,
    GET_LATEST_OPEN_ROOM_ID,
    GET_LATEST_OPEN_ROOM_ID_FAILED,

} from "actions/types";
import { leaveRoom, } from "actions/room";
import AuthService from "services/auth";
import Store from "store/reduxstore";
import Analytics from "analytics";

export const register = (email, password, country) => (dispatch) => {
    return AuthService.register(email, password, country).then(
        (response) => {
            if (response.status == "Error") {
                const message =
                    response.message ||
                    response.message.toString();

                let list = null;
                if (response.messageList) {
                    list = response.messageList
                }

                return Promise.reject(message);
            }
            else {
                dispatch({
                    type: REGISTER_SUCCESS,
                });

                return response;
            }
        },
        (error) => {
            const messageError =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_FAIL,
            });

            return Promise.reject(messageError);
        }
    );
};

export const login = (email, password, QRSessionID = null) => (dispatch) => {
    console.log("do login");
    return AuthService.login(email, password, QRSessionID).then(
        (response) => {
            if (response.isAuthenticated === true) {
                Analytics.login();

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: response },
                });
                //dispatch(getPackOwnership());

                const reduxStore = Store.getState();
                if (reduxStore.room.roomOpen) {
                    reduxStore.room.room.send("update_auth", { token: response.token });
                }

                return Promise.resolve();
            }
            else {
                const messageRes =
                    response.message ||
                    response.message.toString();
                console.log(messageRes);

                dispatch({
                    type: LOGIN_FAIL,
                });

                return Promise.reject(messageRes);
            }
        },
        (error) => {
            const messageError = getErrorMessage(error);

            console.log(messageError);

            dispatch({
                type: LOGIN_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const requestQRSession = (oldSessionID) => (dispatch) => {
    console.log("Requesting QR Login Session");
    return AuthService.requestQRSession(oldSessionID).then(
        (response) => {
            dispatch({
                type: QR_SESSION_REQUEST,
                payload: { QRSession: response },
            });

            return response;
        },
        (error) => {
            const messageError = getErrorMessage(error);
            console.log(messageError);

            dispatch({
                type: QR_SESSION_REQUEST_FAILED,
            });

            return Promise.reject();
        });
}

export const getQRSession = (QRSessionID) => (dispatch) => {
    console.log("checking QR Login Session");
    return AuthService.getQRSession(QRSessionID).then(
        (response) => {
            const sessionData = { sessionID: response.sessionID, expires: response.expires };
            const userData = response.user;

            if (userData) dispatch({
                type: QR_SESSION_LOGIN,
                payload: {
                    QRSession: sessionData,
                    user: userData
                },
            });
            else dispatch({
                type: QR_SESSION_CHECK,
                payload: {
                    QRSession: sessionData,
                },
            });

            if (response.sessionID) {
                localStorage.setItem("QRSessionData", JSON.stringify(sessionData));
                if (userData) localStorage.setItem("user", JSON.stringify(userData));
            }

            return response;
        },
        (error) => {
            const messageError = getErrorMessage(error);
            console.log(messageError);

            dispatch({
                type: QR_SESSION_CHECK_FAIL,
            });

            return Promise.reject();
        });
}

export const getLatestOpenRoom = (QRSessionID) => (dispatch) => {
    console.log("getting latest open room id");
    return AuthService.getLatestRoomID(QRSessionID).then(
        (response) => {

            dispatch({
                type: GET_LATEST_OPEN_ROOM_ID,
                payload: {
                    roomID: response.roomID,
                },
            });

            return response;
        },
        (error) => {
            const messageError = getErrorMessage(error);
            console.log(messageError);

            dispatch({
                type: GET_LATEST_OPEN_ROOM_ID_FAILED,
            });

            return Promise.reject();
        });
}

export const autoLoginQRSession = (QRSessionID, UserID) => (dispatch) => {
    console.log("Auto Login QR Session");
    return AuthService.autoLoginQRSession(QRSessionID, UserID).then(
        (response) => {
            const sessionData = { sessionID: response.sessionID, expires: response.expires };
            const userData = response.user;

            dispatch({
                type: QR_SESSION_LOGIN,
                payload: {
                    QRSession: sessionData,
                    user: userData
                },
            });

            if (response.sessionID) {
                localStorage.setItem("QRSessionData", JSON.stringify(sessionData));
                if (userData) localStorage.setItem("user", JSON.stringify(userData));
            }

            return response;
        },
        (error) => {
            const messageError = getErrorMessage(error);
            console.log(messageError);

            dispatch({
                type: QR_SESSION_LOGIN_FAIL,
            });

            return Promise.reject();
        });
}

export const logout = () => (dispatch) => {
    AuthService.logout();

    const reduxStore = Store.getState();
    if (reduxStore.room.roomOpen) {
        dispatch(leaveRoom());
    }

    dispatch({
        type: LOGOUT,
    });

    //history.push('/');
};

export const setGames = (games) => (dispatch) => {
    const reduxStore = Store.getState();
    const updatedUser = { ...reduxStore.auth.user, games}
    dispatch({
        type: SET_GAMES,
        payload: updatedUser
    });

    //history.push('/');
};


const getErrorMessage = (error) => {
    return (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
}