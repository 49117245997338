import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './constants/AppRoutes';
import { Layout } from './components/Layout/Layout';
import { history } from './helpers/history';
import AuthVerify from "common/AuthVerify";
//import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from 'uuid';
import { updateCartDetails } from "actions/cart";
import Roku from "components/Roku/Roku";

import PrivateRoute from './components/PrivateRoute';
import PlayerList from './components/Room/PlayerList';
import CRMModal from './components/CRMModal/CRMModal';
import RedirectListener from './components/Room/RedirectListener';
import Toasts from 'components/Toasts/Toasts';
import Maintenance from './components/Maintenance';
import Assets from './constants/assets';

import './custom.css';
import 'shared/fonts.scss';

import { setCountryCode } from "actions/pricing";
import { connect } from 'react-redux';
import { withRouter } from './components/withRouter';
import { Helmet } from 'react-helmet';
import { setCookie } from './helpers/cookies';

import Logo from 'images/logo-high-res.png';

history.listen(({ location, action }) => {
    console.log(location);
    window.scrollTo(0, 0)
    console.log('should scroll')
});

//datadogRum.init({
//    applicationId: 'aec6062a-5213-4e24-8f0c-10ea75244fa6',
//    clientToken: 'pub812259580c20c1c3678977ffc0623979',
//    site: 'datadoghq.eu',
//    service: 'game-city',
//    env: 'REACT_APP_ENVIRONMENT',
//    // Specify a version number to identify the deployed version of your application in Datadog
//    // version: '1.0.0',
//    sessionSampleRate: 100,
//    sessionReplaySampleRate: 100,
//    trackUserInteractions: true,
//    trackResources: true,
//    trackLongTasks: true,
//    defaultPrivacyLevel: 'mask-user-input',
//});

const isDev = !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === 'development';

Sentry.init({
    dsn: "https://0478276232ba41328c4f9abe3f6f24a1@o4507028880883712.ingest.us.sentry.io/4507028894121989",
    environment: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'undefined',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: ['https://gb-lhr-5406ce9a.colyseus.cloud/matchmake/create/game_city_room'],
            networkRequestHeaders: ['X-Custom-Header'],
            networkResponseHeaders: ['X-Custom-Header'],
        }),
        Sentry.feedbackIntegration({
            colorScheme: 'system',
            showBranding: false,
            showName: false,
            successMessageText: "Thank you for submitting a report. A member of the Game City team will ensure this is addressed."
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: isDev? 1 : 0.2, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    //tracePropagationTargets: ["localhost", /^https:\/\/gamecityweb-stage\.azurewebsites\.net/],
    // Session Replay
    replaysSessionSampleRate: isDev ? 1 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const underMaintenance = false;


class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            //percentage: 0,
        };
        this.isRoku = window.location.pathname.includes('/roku');
        Sentry.setTag('isRoku', this.isRok);
        console.log(window.location.pathname, this.isRoku);
    }


    componentDidMount() {
        if (!this.props.pricing.countryCode) {
            this.props.setCountryCode();
        }

        if (this.props.cart?.items?.length > 0) {
            this.props.updateCartDetails();
        }

        // if localstorage doesnt have a uniqueId, create one, set it in localstorage and as their user id for sentry
        if (!localStorage.getItem('uniqueId')) {
            localStorage.setItem('uniqueId', uuidv4());
            setCookie('uniqueId', localStorage.getItem('uniqueId'), 365);
        }
        if (this.props.auth.isLoggedIn) {
            Sentry.setUser({ id: localStorage.getItem('uniqueId'), email: this.props.auth.user.email });
        } else {
            Sentry.setUser({ id: localStorage.getItem('uniqueId')});
        }
        

        Promise.all(Assets.images.map(image => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = resolve;
                img.onerror = reject;
                img.src = image;
            }).then(() => {

            });
        })).then(() => {
            // Preload fonts
            return Promise.all(Assets.fonts.map(font => {
                return new Promise((resolve, reject) => {
                    const fontFace = new FontFace(font.font, `url(${font.path})`);
                    fontFace.load().then(() => {
                        document.fonts.add(fontFace);
                        resolve();
                    }).catch(reject);
                });
            }));
        }).then(() => {
            setTimeout(() => {
                this.setState({ isLoading: false });
            }, 1000);
        }).catch(error => {
            // if there's an error, we still want to show the app, so set isLoading to false
            this.setState({ isLoading: false });
        });
    }

    render() {
        return (
            this.isRoku ?
                this.state.isLoading ?
                    <div className={"loadingScreen"}>
                        <img src={Logo} style={{ width: "30vh", height: "auto", marginBottom: "1vh" }} alt="loading" />
                        <div className="dot-pulse"></div>
                    </div>
                    :
                <React.Fragment>
                    <Roku />
                    <PlayerList isRoku={true} />
                    <AuthVerify isRoku={true} />
                    <RedirectListener isRoku={true} />
                </React.Fragment>
                :
                <React.Fragment>
                    <Helmet>
                        <link rel="canonical" href="https://game.city" />
                    </Helmet>
                    {
                        this.state.isLoading ?
                            <div className={"loadingScreen"}>
                                <img src={Logo} style={{ width: "30vh", height: "auto", marginBottom: "1vh" }} alt="loading"/>
                                <div className="dot-pulse"></div>
                            </div>
                            :
                            <>
                                {
                                    underMaintenance /*&& window.location.hostname.includes("game.city")*/ ?
                                        <Maintenance />
                                        :
                                        < Layout >
                                            <React.Fragment>
                                                <Routes>
                                                    {AppRoutes.map((route, index) => {
                                                        const { element, ...rest } = route;
                                                        if (rest.private) {
                                                            return <Route exact path={rest.path} key={index} element={<PrivateRoute path={route.path} />}>

                                                                <Route exact path={rest.path} key={index} element={element} />
                                                            </Route>
                                                        } else {
                                                            return <Route path={rest.path} key={index} {...rest} element={element} />;
                                                        }
                                                    })}
                                                </Routes>
                                                <PlayerList />
                                                <Toasts/>
                                                <CRMModal/>
                                                <RedirectListener />
                                                <AuthVerify />
                                            </React.Fragment>
                                        </Layout>
                                }
                            </>
                    }
                </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pricing: state.pricing,
        cart: state.cart,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCountryCode: (game) => {
            dispatch(setCountryCode())
        },
        updateCartDetails: () => {
            dispatch(updateCartDetails())
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
