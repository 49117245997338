import React, { Component } from 'react';
import { connect, } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { Link, } from 'react-router-dom';
import { withRouter } from '../withRouter';
import { logout } from "actions/auth.js";
import styles from "components/Layout/NavStyles.module.scss";
import logo from '../../images/logo.png';
import basketIcon from '../../images/icons/basketIcon.png';
import burgerMenu from '../../images/icons/burgerMenu.png'
import userIcon from '../../images/icons/userIcon.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXTwitter, faInstagram, faFacebook, faYoutube, faTiktok } from "@fortawesome/free-brands-svg-icons";

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.getUrl();

        this.state = {
            showMenu: false,
            showUserMenu: false,
        };
        this.wrapperRefOne = React.createRef();
        this.wrapperRefTwo = React.createRef();
        this.userButtonRef = React.createRef();
        this.menuButtonRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.showMenu && !this.wrapperRefOne?.current?.contains(event.target) && !this.menuButtonRef?.current?.contains(event.target)) {
            this.hideMenu();
        }
        if (this.showUserMenu && !this.wrapperRefTwo?.current?.contains(event.target) && !this.userButtonRef?.current?.contains(event.target)) {
            console.log("test");
            this.hideUserMenu();
        }
    }


    showMenu = () => {
        this.setState({ showMenu: true, });
    }

    hideMenu = () => {
        this.setState({ showMenu: false, });
    }

    toggleMenu = () => {
        this.setState({ showMenu: !this.state.showMenu });
        this.hideUserMenu();
    } 

    showUserMenu = () => {
        this.setState({ showUserMenu: true });
    }

    hideUserMenu = () => {
        this.setState({ showUserMenu: false });
    }

    toggleUserMenu = (e) => {
        console.log("user menu");
        this.setState({ showUserMenu: !this.state.showUserMenu });
        this.hideMenu()
    }

    isMenuShowing = () => {
        return this.state.showMenu ? styles.show : styles.hide;
    }

    getUrl = () => {
        return window.location.href;
    }


    isAGameStorePage = () => {
        const availableGameSlugs = [
            'herd-mentality',
            'can-of-squirms',
            'scrawl',
            'the-chameleon',
            'draw-along-with-dave',
            'colour-brain',
            'pub-quiz',
        ];


        const matchedGame = availableGameSlugs.find((x) => x === window.location.href.split('/').slice(-1)[0])

        switch (matchedGame) {
            case 'herd-mentality':
                return styles.herdMentality;
            case 'can-of-squirms':
                return styles.canOfSquirms;
            case 'scrawl':
                return styles.scrawl;
            case 'the-chameleon':
                return styles.theChameleon;
            case 'draw-along-with-dave':
                return styles.drawAlongWithDave;
            case 'colour-brain':
                return styles.colourBrain;
            case 'pub-quiz':
return styles.pubQuiz;
            default:
                return null;
        }

    }

    doNavigation = (page) => {
        this.props.navigate(page);
        this.hideMenu();
    }

    logout = (e) => {
        e.preventDefault();
        this.props.logout();
        this.hideMenu();
        this.props.navigate("/");
    }

    countCartQuanity = () => {
        // Counts the quantity of items in the cart

        // The last thing to get this working is for this method to run whenever the cart updates

        if (this.props.cart) {
            const cart = this.props.cart;
            let total = 0;

            cart.items.forEach(item => {
                total += item.quantity
            })

            return total;
        } else {
            return 0;
        }
        
    }

    openInNewTab(url) {
        window.open(url, '_blank').focus();
    }

    render() {
        const gameStyle = this.isAGameStorePage()
        return (
            <>
            <header className={`${styles.header} ${gameStyle}`}>
                <Container fluid className={styles.headerInner}>
                    <div className={`${styles.logo}`}>
                        <img onClick={() => this.doNavigation("/")} src={logo} alt="" width="153" height="80"></img>
                    </div>

                    <div ref={this.wrapperRefOne} className={`${styles.menuItems} ${styles.mainMenu} ${this.isMenuShowing()} text-center`}>
                        <ul>
                            <li>
                                <Link className={`${window.location.pathname.includes("shop") && styles.selected}`} onClick={this.hideMenu} to="/shop">Shop</Link>
                            </li>
                                {!this.props.room.roomOpen && <li>
                                    <Link className={`${window.location.pathname.includes("lobby") && styles.selected}`} onClick={this.hideMenu} to="/lobby">Start a Game</Link>
                                </li>}
                            <li>
                                    <Link className={`${(window.location.pathname.includes("join") || window.location.pathname.includes("play")) && styles.selected}`} onClick={this.hideMenu} to="/join">{this.props.room.roomOpen ? "Return to lobby" : "Join a Game"}</Link>
                            </li>
                            <li>
                                <Link className={`${window.location.pathname.includes("help") && styles.selected}`} onClick={this.hideMenu} to="/help">Help</Link>
                            </li>
                            <li>
                                <Link className={`${window.location.pathname.includes("redeem-code") && styles.selected}`} onClick={this.hideMenu} to="redeem-code">Redeem Code</Link>
                            </li>
                        </ul>
                    </div>

                    <div className={`${styles.menuItems} ${styles.actions}`}>
                        <ul>
                            <li>
                                    <div className={styles.actionsItem} onClick={this.toggleUserMenu} ref={this.userButtonRef} >
                                    <img src={userIcon} alt="" width="46" height="46"></img>
                                    {this.state.showUserMenu &&
                                        <ul ref={this.wrapperRefTwo}>
                                            {
                                                this.props.auth.isLoggedIn ?
                                                    <React.Fragment>
                                                        <li>
                                                            <span onClick={this.logout}>Logout</span>
                                                        </li>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <li>
                                                            <span onClick={() => this.doNavigation("/login")}>Login</span>
                                                        </li>
                                                        <li>
                                                            <span onClick={() => this.doNavigation("/register")}>Register</span>
                                                        </li>
                                                    </React.Fragment>
                                            }
                                        </ul>
                                    }

                                </div>
                            </li>
                            <li>
                                <div className={styles.actionsItem}>
                                    <Link to="/checkout" className={styles.basketIcon}>
                                        <img src={basketIcon} alt="" width="42" height="45" data-basket-count={this.countCartQuanity()}></img>
                                        {
                                            this.countCartQuanity() > 0 &&
                                            <p className={styles.basketCount}>{this.countCartQuanity()}</p>
                                        }
                                    </Link>
                                </div>
                            </li>
                            <li>
                                    <div className={`${styles.actionsItem}  d-lg-none`} onClick={this.toggleMenu} ref={this.menuButtonRef} >
                                    <img src={burgerMenu} alt="" width="45" height="30"></img>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Container>
                </header>

                {!window.location.pathname.includes("/shop") && !window.location.pathname.includes('/lobby') && !window.location.pathname.includes('/play') &&
                    <header className={`${styles.socialsBar} ${gameStyle}`}>
                        <FontAwesomeIcon icon={faXTwitter} onClick={() => this.openInNewTab("https://x.com/PlayGameCity")} target="_blank" />
                        <FontAwesomeIcon icon={faFacebook} onClick={() => this.openInNewTab("https://www.facebook.com/people/GameCity-Collective/61558537700430/")} target="_blank" />
                        <FontAwesomeIcon icon={faInstagram} onClick={() => this.openInNewTab("https://www.instagram.com/playgame.city/")} target="_blank" />
                        <FontAwesomeIcon icon={faTiktok} onClick={() => this.openInNewTab("https://www.tiktok.com/@gamecityplay")} target="_blank" />
                        <FontAwesomeIcon icon={faYoutube} onClick={() => this.openInNewTab("https://www.youtube.com/channel/UCQ2umAKfPvIbsdzaJvZWaKA")} target="_blank" />
                    </header>
                }
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        room: state.room,
        cart: state.cart,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout())
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavMenu));
