import React, { Component } from 'react';
import { connect, } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { login, register } from 'actions/auth';
import { withRouter } from '../withRouter';
import styles from '../Forms/FormStyles.module.scss'
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

class Register extends Component {
    static displayName = Register.name;

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            confirmEmail: "",
            password: "",
            loading: false,
            errorMessage: "",
            validated: false,
            QRSessionID: new URLSearchParams(window.location.search).get("QRSessionID"),
        };
    }

    componentWillMount() {
        if (this.props.auth.isLoggedIn) {
            if (this.state.QRSessionID) return setTimeout(() => this.props.navigate(`/join/?QRSessionID=${this.state.QRSessionID}`));
            return setTimeout(() => this.props.navigate("/shop"));
        }
    }

    componentDidUpdate(nextProps, nextState) {
        if (this.props.auth.isLoggedIn) {
            if (this.state.QRSessionID) return setTimeout(() => this.props.navigate(`/join/?QRSessionID=${this.state.QRSessionID}`));
            return setTimeout(() => this.props.navigate("/shop"));
        }
    }

    onChangeEmail = (e) => {
        const email = e.target.value;

        this.setState({ email });
    };

    onChangeConfirmEmail = (e) => {
        const confirmEmail = e.target.value;

        this.setState({ confirmEmail });
    }

    onChangePassword = (e) => {
        const password = e.target.value;
        const validLength = password.length >= 8;
        const hasNumber = (/\d/g).test(password);
        const upperCase = (/[A-Z]/g).test(password);
        const lowerCase = (/[a-z]/g).test(password);
        this.setState({ password, validLength, hasNumber, upperCase, lowerCase });
    };

    handleRegister = (e) => {
        e.preventDefault();

        if (!this.state.loading) {
            this.setState({ loading: true, errorMessage: "", });
            if (this.validate()) {
                const email = this.state.email;
                const password = this.state.password;
                this.props.register(email, password, this.props.pricing.countryCode)
                    .then((res) => {
                        console.log(res);
                        this.props.login(email, password, this.state.QRSessionID)
                            .then((res) => {
                                console.log(res);
                                if (this.props.modal) {
                                    this.props.closeModal(true);
                                } else {
                                    this.props.navigate(`/join/?QRSessionID=${this.state.QRSessionID}`)
                                    //this.props.navigate("/shop");
                                }
                            })
                            .catch((err) => {
                                this.setState({ loading: false });
                            });
                    })
                    .catch((err) => {
                        console.log("register err: ", err);
                        this.setState({ loading: false, errorMessage: err, });
                    });
            } else {
                this.setState({ loading: false, });
            }
        }
    };

    validate = () => {
        this.setState({ validated: true });
        // first check emails match
        if (this.state.email !== this.state.confirmEmail) {
            this.setState({ errorMessage: "Emails do not match", });
            return false;
        }

        if (!this.state.validLength || !this.state.hasNumber || !this.state.upperCase || !this.state.lowerCase) {
            this.setState({ errorMessage: "This password doesn't meet the minimum requirements", });
            return false;
        }

        return true;
    }

    goToLogin = () => {
        if (this.state.QRSessionID) this.props.navigate(`/login/?QRSessionID=${this.state.QRSessionID}`);
        else this.props.navigate("/login");
    }

    render() {
        return (

            <Form onSubmit={this.handleRegister}>

                <h2 className={styles.formTitle}>Register</h2>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" required onChange={this.onChangeEmail} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formConfirmEmail">
                    <Form.Label>Confirm Email address</Form.Label>
                    <Form.Control type="email" placeholder="Confirm email" required onChange={this.onChangeConfirmEmail} isInvalid={this.state.email !== this.state.confirmEmail} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control className="mb-2" type="password" placeholder="Password" required onChange={this.onChangePassword} isInvalid={!this.state.validLength || !this.state.hasNumber || !this.state.upperCase || !this.state.lowerCase}></Form.Control>
                    <ul className={styles.passwordRequirements}>
                        <li className={this.state.validLength ? "text-success" : "text-danger"}>
                            Your password must be at least 8 characters
                        </li>
                        <li className={this.state.hasNumber ? "text-success" : "text-danger"}>
                            Your password must include a number
                        </li>
                        <li className={this.state.upperCase ? "text-success" : "text-danger"}>
                            Your password must include an upper case letter
                        </li>
                        <li className={this.state.lowerCase ? "text-success" : "text-danger"}>
                            Your password must include a lower case letter
                        </li>
                    </ul>

                </Form.Group>
                {
                    this.state.errorMessage.length > 0 &&
                    <Alert variant={"danger"}>
                        {this.state.errorMessage}
                    </Alert>
                }

                <div className={styles.buttonsWrapper}>
                    <button className={`${styles.button} ${styles.alternate}`} type="button" onClick={() => this.goToLogin()} style={{ marginRight: '10px' }}>Login</button>
                    <button className={styles.button} type="submit">{this.state.loading ? <Spinner animation="border" /> : "Register"}</button>
                </div>
                <p>
                    By registering you are agreeing to the <Link to="/terms">Game.City Terms</Link>
                </p>
            </Form>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pricing: state.pricing,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        register: (email, password, country) => {
            return dispatch(register(email, password, country))
        },
        login: (email, password, QRSessionID) => {
            return dispatch(login(email, password, QRSessionID))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));
