// Game Pack 1 & 2
import GamePack1StoreBackground from "images/storeBackgrounds/pack-1-bg-large.png";
import GamePack1StoreLogo from "images/storeLogos/gamePack1.png";
import GamePack1CardLogo from "images/gamePackCards/pack1-tatos.png";
import GamePack1CardBG from "images/gamePackCards/background/pack-1-bg.png";
import GamePack1TinyCard from "images/tinyCards/Pack 1.png";
import GamePack2TinyCard from "images/tinyCards/Pack 2.png";
import BrainGamesTinyCard from "images/tinyCards/Brain Games.png";

import GamePack2StoreBackground from "images/storeBackgrounds/pack-2-bg-large.png";
import GamePack2StoreLogo from "images/storeLogos/pack-2-logo.png";
import GamePack2CardLogo from "images/gamePackCards/pack2-tatos.png";
import GamePack2CardBG from "images/gamePackCards/background/pack-2-bg.png";

import BigPotoatoPackStoreBackground from "images/storeBackgrounds/pack-2-bg-large.png";
import BigPotoatoPackStoreLogo from "images/storeLogos/BigPotatoPack.png";
import BigPotoatoPackCardLogo from "images/gamePackCards/pack2-tatos.png";
import BigPotoatoPackCardBG from "images/gamePackCards/background/pack-2-bg.png";

import ScrawlLogo from "images/games/ScrawlLogo.png";
import HerdLogo from "images/games/HerdLogo.png";
import SquirmsLogo from "images/games/SquirmsLogo.png";
import ScrawlMobilePreview1 from "images/gameStorePreviews/scrawl/scrawl-mobile-1.png";
import ScrawlDesktopPreview1 from "images/gameStorePreviews/scrawl/scrawl-tv-ls.png";
import ScrawlTinyCard from "images/tinyCards/Scrawl.png";
import HerdMobilePreview1 from "images/gameStorePreviews/herd/herd-mobile-1.png";
import HerdDesktopPreview1 from "images/gameStorePreviews/herd/herd-hero.png";
import HerdTinyCard from "images/tinyCards/Herd Mentality.png";
import SquirmsMobilePreview1 from "images/gameStorePreviews/squirms/squirms-mobile-1.png";
import SquirmsDesktopPreview1 from "images/gameStorePreviews/squirms/squirms-tv-ls.png";
import SquirmsTinyCard from "images/tinyCards/Can of Squirms.png";
import ScrawlHero from "images/heros/scrawl.jpg";
import HerdHero from "images/heros/herd-mentality.jpg";
import SquirmsHero from "images/heros/can-of-squirms.jpg";

// Draw With Dave
import DrawWithLogo from "images/games/DrawWithLogo.png";
import DrawWithMobilePreview1 from "images/gameStorePreviews/drawWith/drawwith-mobile-1.png";
import DrawWithDesktopPreview1 from "images/gameStorePreviews/drawWith/drawwith-desktop-1.png";
import DrawWithTinyCard from "images/tinyCards/Draw Along with Dave.png";
import DaveHero from "images/heros/draw-with-dave.jpg";

// The Chameleon
import ChameleonLogo from "images/games/ChameleonLogo.png";
import ChameleonMobilePreview1 from "images/gameStorePreviews/theChameleon/chameleon-mobile-1.png";
import ChameleonDesktopPreview1 from "images/gameStorePreviews/theChameleon/chameleon-desktop-1.png";
import ChameleonTinyCard from "images/tinyCards/The Chameleon.png";
import ChameleonHero from "images/heros/the-chameleon.jpg";

// Colour Brain
import ColourBrainLogo from "images/games/ColourBrainLogo.png";
import ColourBrainMobilePreview1 from "images/gameStorePreviews/colourBrain/colourbrain-mobile-1.png";
import ColourBrainDesktopPreview1 from "images/gameStorePreviews/colourBrain/colourbrain-desktop-1.png";
import ColourBrainTinyCard from "images/tinyCards/Colourbrain.png";
import ColourBrainHero from "images/heros/colour-brain.jpg";

import Pack1Video from "videos/pack-1-video.mp4";
import Pack2Video from "videos/pack-2-video.mp4";

// Brain Games
import BrainGamesLogo from "images/games/brain-games-logo.png";
import BrainGamesBackground from "images/storeBackgrounds/brain-games-background.png";

// Anagram Magic
import AnagramMagicLogo from "images/games/anagram-magic-logo.png";
import AnagramMagicTinyCard from "images/tinyCards/anagram-magic-tinycard.png";
import AnagramlHero from "images/heros/anagram-magic.jpg.png";

// Maths Master
import MathsMasterLogo from "images/games/maths-master-logo.png";
import MathsMasterTinyCard from "images/tinyCards/maths-master-tinycard.png";
import MathsHero from "images/heros/maths-master.png";

// Word Wheel
import WordWheelLogo from "images/games/word-wheel-logo.png";
import WordWheelTinyCard from "images/tinyCards/word-wheel-tinycard.png";
import WordHero from "images/heros/word-wheel.png";

// Speedy Sums
import SpeedySumsLogo from "images/games/speedy-sums-logo.png";
import SpeedySumsTinyCard from "images/tinyCards/speedy-sums-tinycard.png";


const GameList = [
    {
        type: "Pack",
        name: "Brain Games",
        subNames: ["Brain Games"],
        pack: 3,
        gameId: "brain_games",
        enabled: true,
        logo: BrainGamesLogo,
        description: "In this pack you'll get access to the Brain Games Collection; Anagram Magic, Maths Master & Word Wheel!",
        productId: process.env.REACT_APP_BRAINGAMES_PID,
        price: 16.99,
        // gameURL: process.env.REACT_APP_SCRAWL_URL,
        storeSlug: '/shop/brain-games',
        label: "",
        gamePackInfo: {
            title: `Brain Games`,
            subTitle: "Three Brain Training Party games for friends and family!",
            stars: 5,
            minPlayers: 1,
            avgRoundLengthText: '10+ mins',
            minAge: 8,
            gamesIncluded: [
                {
                    name: "Anagram Magic",
                    logo: AnagramMagicLogo,
                    pack: 3,
                    gameId: "anagram_magic",
                    description: "",
                    storeSlug: '/shop/anagram-magic',
                    tinyCard: AnagramMagicTinyCard,
                    mobile1: ChameleonMobilePreview1,
                    desktop1: ChameleonDesktopPreview1,
                },
                {
                    name: "Maths Master",
                    logo: MathsMasterLogo,
                    pack: 3,
                    gameId: "maths_master",
                    description: "",
                    storeSlug: '/shop/maths-master',
                    tinyCard: MathsMasterTinyCard,
                    mobile1: ChameleonMobilePreview1,
                    desktop1: ChameleonDesktopPreview1,
                },
                {
                    name: "Word Wheel",
                    logo: WordWheelLogo,
                    pack: 3,
                    gameId: "word_wheel",
                    description: "",
                    storeSlug: '/shop/word-wheel',
                    tinyCard: WordWheelTinyCard,
                    mobile1: ChameleonMobilePreview1,
                    desktop1: ChameleonDesktopPreview1,
                },
                //{
                //    name: "Speedy Sums",
                //    logo: SpeedySumsLogo,
                //    pack: 3,
                //    gameId: "speedy_sums",
                //    description: "",
                //    storeSlug: '/shop/speedy-sums',
                //    tinyCard: SpeedySumsTinyCard,
                //    mobile1: ChameleonMobilePreview1,
                //    desktop1: ChameleonDesktopPreview1,
                //}
            ]
        },
        meta: {
            heros: [AnagramlHero, MathsHero, WordHero],
            cardLogo: BrainGamesLogo,
            cardBackground: BrainGamesBackground,
            cardPriceColour: '#10232B',
            storeBackground: BrainGamesBackground,
            storeInfoBackground: '#FFFFFF',
            storeLogo: BrainGamesLogo,
            storeAccentColour: '#212B3D',
            video: Pack2Video,
            tinyCard: BrainGamesTinyCard,
        },
        pricing: {
            gb: {
                raw: 16.99,
                display: "\u00A316.99",
                currency: "\u00A3"
            },
            us: {
                raw: 23.50,
                display: "\u002423.50",
                currency: "\u0024"
            }
        }
    },
    {
        type: "Pack",
        name: "Big Screen Games - Pack 1",
        subNames: ["Big Screen Games", "Pack 1"],
        pack: 1,
        gameId: "game_pack_1",
        enabled: true,
        logo: GamePack1StoreLogo,
        description: "Inside our first party pack, you'll get online versions of Herd Mentality, Can of Squirms and Scrawl - each tweaked to be extra family friendly.",
        productId: process.env.REACT_APP_PACK1_PID,
        price: 18.99,
        // gameURL: process.env.REACT_APP_SCRAWL_URL,
        storeSlug: '/shop/game-pack-1',
        label: "Pack 1",
        gamePackInfo: {
            title: `PACK 1: \n3 PARTY GAMES TO PLAY ON YOUR TV`,
            subTitle: "Three hilarious party games, instantly delivered to your living room.",
            stars: 5,
            minPlayers: 3,
            avgRoundLengthText: '20+ mins',
            minAge: 8,
            gamesIncluded: [
                {
                    name: "Scrawl",
                    logo: ScrawlLogo,
                    pack: 1,
                    gameId: "scrawl",
                    description: "A drawing game that can go very wrong, very quickly",
                    storeSlug: '/shop/scrawl',
                    tinyCard: ScrawlTinyCard,
                    mobile1: ScrawlMobilePreview1,
                    desktop1: ScrawlDesktopPreview1,
                },
                {
                    name: "Herd Mentality",
                    logo: HerdLogo,
                    pack: 1,
                    gameId: "herd_mentality",
                    description: "One black and white mission: don't be the odd one out",
                    storeSlug: '/shop/herd-mentality',
                    tinyCard: HerdTinyCard,
                    mobile1: HerdMobilePreview1,
                    desktop1: HerdDesktopPreview1,
                },
                {
                    name: "Can of Squirms",
                    logo: SquirmsLogo,
                    pack: 1,
                    gameId: "can_of_squirms",
                    description: "Awkward questions that you can't un-ask",
                    storeSlug: '/shop/can-of-squirms',
                    tinyCard: SquirmsTinyCard,
                    mobile1: SquirmsMobilePreview1,
                    desktop1: SquirmsDesktopPreview1,
                },
            ]
        },
        meta: {
            heros: [ScrawlHero, HerdHero, SquirmsHero],
            cardLogo: GamePack1CardLogo,
            cardBackground: GamePack1CardBG,
            cardPriceColour: '#10232B',
            storeBackground: GamePack1StoreBackground,
            storeInfoBackground: '#FFFFFF',
            storeLogo: GamePack1StoreLogo,
            storeAccentColour: '#212B3D',
            video: Pack1Video,
            tinyCard: GamePack1TinyCard,
        },
        pricing: {
            gb: {
                raw: 19.99,
                display: "\u00A319.99",
                currency: "\u00A3"
            },
            us: {
                raw: 28.50,
                display: "\u002428.50",
                currency: "\u0024"
            }
        }
    },
    {
        type: "Pack",
        name: "Big Screen Games - Pack 2",
        subNames: ["Big Screen Games", "Pack 2"],
        pack: 2,
        gameId: "game_pack_2",
        enabled: true,
        logo: GamePack2StoreLogo,
        description: "Inside our second party pack, you'll get online versions of Colour Brain, Chameleon and our brand new game exclusive to Game.City; Draw along with Dave!",
        productId: process.env.REACT_APP_PACK2_PID,
        price: 18.99,
        // gameURL: process.env.REACT_APP_SCRAWL_URL,
        storeSlug: '/shop/game-pack-2',
        label: "Pack 2",
        gamePackInfo: {
            title: "PACK 2: \n3 PARTY GAMES TO PLAY ON YOUR TV",
            subTitle: "Three hilarious party games, instantly delivered to your living room.",
            stars: 5,
            minPlayers: 2,
            avgRoundLengthText: '20+ mins',
            minAge: 8,
            gamesIncluded: [
                {
                    name: "Chameleon",
                    logo: ChameleonLogo,
                    pack: 2,
                    gameId: "chameleon",
                    description: "Pick a secret word, then try to catch out the guilty player",
                    storeSlug: '/shop/the-chameleon',
                    tinyCard: ChameleonTinyCard,
                    mobile1: ChameleonMobilePreview1,
                    desktop1: ChameleonDesktopPreview1,
                },
                {
                    name: "Draw along with Dave",
                    logo: DrawWithLogo,
                    pack: 2,
                    gameId: "draw_with",
                    description: "Pick up your paintbrush and create your masterpiece",
                    storeSlug: '/shop/draw-along-with-dave',
                    tinyCard: DrawWithTinyCard,
                    mobile1: DrawWithMobilePreview1,
                    desktop1: DrawWithDesktopPreview1,
                },
                {
                    name: "Colour Brain",
                    logo: ColourBrainLogo,
                    pack: 2,
                    gameId: "colour_brain",
                    description: "A party trivia game where every answer is a colour",
                    storeSlug: '/shop/colour-brain',
                    tinyCard: ColourBrainTinyCard,
                    mobile1: ColourBrainMobilePreview1,
                    desktop1: ColourBrainDesktopPreview1,
                }
            ]
        },
        meta: {
            heros: [ChameleonHero, DaveHero, ColourBrainHero],
            cardLogo: GamePack2CardLogo,
            cardBackground: GamePack2CardBG,
            cardPriceColour: '#10232B',
            storeBackground: GamePack2StoreBackground,
            storeInfoBackground: '#FFFFFF',
            storeLogo: GamePack2StoreLogo,
            storeAccentColour: '#212B3D',
            video: Pack2Video,
            tinyCard: GamePack2TinyCard,
        },
        pricing: {
            gb: {
                raw: 19.99,
                display: "\u00A319.99",
                currency: "\u00A3"
            },
            us: {
                raw: 28.50,
                display: "\u002428.50",
                currency: "\u0024"
            }
        }
    },
    {
        type: "Pack",
        name: "Big Potato Pack",
        subNames: ["Big Potato Pack"],
        pack: 4,
        gameId: "big_potato_pack",
        enabled: false,
        logo: GamePack1StoreLogo,
        description: "Inside our Big Potato pack, you'll get online versions of Can of Squirms, Draw Along with Dave and Colour Brain - each tweaked to be extra family friendly.",
        productId: process.env.REACT_APP_BIGPOTATOPACK_PID,
        price: 24.99,
        // gameURL: process.env.REACT_APP_SCRAWL_URL,
        storeSlug: '/shop/big-potato-pack',
        label: "",
        gamePackInfo: {
            title: `3 PARTY GAMES TO PLAY ON YOUR TV`,
            subTitle: "Three hilarious party games, instantly delivered to your living room.",
            stars: 5,
            minPlayers: 3,
            avgRoundLengthText: '20+ mins',
            minAge: 8,
            gamesIncluded: [
                {
                    name: "Can of Squirms",
                    logo: SquirmsLogo,
                    pack: 1,
                    gameId: "can_of_squirms",
                    description: "Awkward questions that you can't un-ask",
                    storeSlug: '/shop/can-of-squirms',
                    tinyCard: SquirmsTinyCard,
                    mobile1: SquirmsMobilePreview1,
                    desktop1: SquirmsDesktopPreview1,
                },
                {
                    name: "Draw along with Dave",
                    logo: DrawWithLogo,
                    pack: 2,
                    gameId: "draw_with",
                    description: "Pick up your paintbrush and create your masterpiece",
                    storeSlug: '/shop/draw-along-with-dave',
                    tinyCard: DrawWithTinyCard,
                    mobile1: DrawWithMobilePreview1,
                    desktop1: DrawWithDesktopPreview1,
                },
                {
                    name: "Colour Brain",
                    logo: ColourBrainLogo,
                    pack: 2,
                    gameId: "colour_brain",
                    description: "A party trivia game where every answer is a colour",
                    storeSlug: '/shop/colour-brain',
                    tinyCard: ColourBrainTinyCard,
                    mobile1: ColourBrainMobilePreview1,
                    desktop1: ColourBrainDesktopPreview1,
                }
            ]
        },
        meta: {
            heros: [ColourBrainHero, SquirmsHero, DaveHero],
            cardLogo: BigPotoatoPackCardLogo,
            cardBackground: BigPotoatoPackCardBG,
            cardPriceColour: '#10232B',
            storeBackground: BigPotoatoPackStoreBackground,
            storeInfoBackground: '#FFFFFF',
            storeLogo: BigPotoatoPackStoreLogo,
            storeAccentColour: '#212B3D',
            video: Pack1Video,
            tinyCard: GamePack2TinyCard,
        },
        pricing: {
            gb: {
                raw: 24.99,
                display: "\u00A324.99",
                currency: "\u00A3"
            },
            us: {
                raw: 31.99,
                display: "\u002431.99",
                currency: "\u0024"
            }
        }
    },
];

export default GameList;
