import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { Container, } from 'react-bootstrap';
import { withRouter } from './withRouter';

import Game from "./Games/Game";
import GamePack from "./Games/GamePack";
import GameAddon from './Games/GameAddon';
import GameList from "constants/GameList";
import GamePackList from "constants/GamePackList";
import GameAddonList from 'constants/GameAddonList';
import styles from './ShopStyles.module.scss'

const categories = {
    all: {
        id: "all-products",
        shortName: 'all products',
        longName: 'all products',
    },
    individual: {
        id: "party-games",
        shortName: 'party games',
        longName: 'amazing party games',
    },
    packs: {
        id: "party-game-packs",
        shortName: 'party packs',
        longName: 'party game packs',
    },
    addons: {
        id: "add-ons",
        shortName: 'add-ons',
        longName: 'party game add-ons',
    },
};

class Shop extends Component {
    static displayName = Shop.name;

    constructor(props) {
        super(props);

        this.state = {
            filter: categories.all.id,
        };
    }

    componentDidMount() {
        // const queryParams = new URLSearchParams(window.location.search);
        // console.log(queryParams);
        // if (queryParams.has("roomId") && queryParams.has("sessionId")) {
        //     console.log("has params");
        //     setTimeout(() => this.props.navigate(`/join/?roomId=${queryParams.get("roomId")}&sessionId=${queryParams.get("sessionId")}`));
        // }
    }

    setFilter(value) {
        this.setState({ filter: value })
    }

    isDev() { return !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === 'development' }

    render() {
        return (
            <div className={styles.pageShop}>
                {/*<section className={`${styles.heroBackground} ${styles.band}`}>*/}
                {/*    <Container className="text-center">*/}
                {/*        <h1>Our Games</h1>*/}
                {/*    </Container>*/}
                {/*</section>*/}

                {/*<section className={styles.filterSection}>*/}
                {/*    <Container className="text-center">*/}
                {/*        <ul className={styles.filterBar}>*/}
                {/*            {Object.entries(categories).map(([key, value]) => {*/}
                {/*                return <li onClick={() => this.setFilter(value.id)} className={(this.state.filter === value.id && styles.active)}>*/}
                {/*                    {value.shortName}*/}
                {/*                </li>*/}
                {/*            })}*/}
                {/*        </ul>*/}
                {/*    </Container>*/}
                {/*</section>*/}

                <section className={`${styles.gameListingSection} ${styles.band}`}>
                    <Container>
                        {
                            [categories.packs.id, categories.all.id].includes(this.state.filter) &&
                            <>
                                <h2 id={categories.packs.id} className='mb-5'>{categories.packs.longName.toUpperCase()}</h2>
                                <div className='row justify-content-center mb-5'>
                                    {GamePackList.filter(g => g.enabled || this.isDev()).map(g => <div className='col-md-6 col-lg-4 text-center'><GamePack game={g} /></div>)}
                                </div>
                            </>
                        }
                        {
                            [categories.individual.id, categories.all.id].includes(this.state.filter) &&
                            <>
                                <h2 id={categories.individual.id} className='mb-5 mt-1'>{categories.individual.longName.toUpperCase()}</h2>
                                <div className='row justify-content-center mb-5'>
                                    {GameList.filter(g => g.enabled || this.isDev()).map(g => <div className='col-md-6 col-lg-4 text-center'><Game game={g} /></div>)}
                                </div>
                            </>
                        }
                        {
                            [categories.addons.id, categories.all.id].includes(this.state.filter) &&
                            <>
                                <h2 id={categories.addons.id} className='mb-5'>{categories.addons.longName.toUpperCase()}</h2>
                                <div className='row justify-content-center mb-5'>
                                    {GameAddonList.filter(a => a.enabled || this.isDev()).map(a => <div className='col-md-6 col-lg-4 text-center'><GameAddon addon={a} /></div>)}
                                </div>
                            </>
                        }
                    </Container>
                </section>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps, null)(withRouter(Shop));
