import React, { Component, useRef } from 'react';
import { connect, } from 'react-redux';
import { Container, Spinner, } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import { Howl, Howler } from "howler";
import { createRoom, reconnectToRoom, } from 'actions/room';
import * as Sentry from "@sentry/react";

import GameList from "constants/GameList";
import AuthService from 'services/auth';
import RokuWelcome from 'components/Roku/Welcome/RokuWelcome';

import styles from './Roku.module.scss';

import Game from "components/Games/Game";
import GameMenu from "components/Room/GameMenu";

import lobbyBgMusic from "audio/lobby-bg-music.mp3";
import joinedSFX from "audio/player-joined.wav";
import disconnectingSFX from "audio/disconnecting.wav";
import disconnectedSFX from "audio/disconnected.wav";
import hoverOrClickSFX from "audio/hover-click.mp3";
import newGameSFX from "audio/new-game.wav";
import errorSFX from "audio/error.wav";
import ErrorModal from '../Utlility/ErrorModal';

import { RoomErrorCodes } from 'constants/ErrorCodes';

import muteButton from "images/icons/lobby_unmuted.png"
import mutedButton from "images/icons/lobby_muted.png"
import fullscreenButton from "images/icons/lobby_fullscreen.png"


const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

var audio = {
    lobbyBg: {
        import: lobbyBgMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    joined: {
        import: joinedSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    disconnecting: {
        import: disconnectingSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    disconnected: {
        import: disconnectedSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    hoverOrClick: {
        import: hoverOrClickSFX,
        loaded: null,
        volume: 0.35,
        loop: false,
    },
    newGame: {
        import: newGameSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    error: {
        import: errorSFX,
        loaded: null,
        volume: 1,
        loop: false,
    }
};

class Roku extends Component {
    static displayName = Roku.name;

    constructor(props) {
        super(props);

        this.state = {
            connected: false,
            userGames: [],
            userAddons: [],
            loadingGames: false,
            roomError: false,
            errorMessage: "",
            selectedGame: null,
            notifications: [],
            roomLoadingTitle: "",
            acceptedScreenSizeWarning: false,
            muted: false,
        };

        //document.addEventListener('keydown', this.handleKeyPress);
    }

    //handleKeyPress = (event) => {
    //    console.log(`Key pressed: ${event.key}`);
    //    if (event.key === "ArrowRight") {
    //        event.preventDefault();
    //    }
    //};

    componentDidMount() {
        this.hideSentryFeedback() //HACK: just a quick way to hide the sentry feedback form on roku, this could probalby be done better
        this.initAudio();
        this.createOrReconnectRoom();
    }

    createOrReconnectRoom(QRSessionID = null) {
        if (this.props.auth.isLoggedIn) { // user logged in?
            if (this.props.room.roomOpen === false) { // room already open?
                const queryParams = new URLSearchParams(window.location.search);
                if (queryParams.has("token")) { // is attempting reconnect?
                    // reconnect here
                    this.doReconnect(queryParams.get("token"));
                } else {
                    console.log("Creating Room...");
                    this.doCreate(QRSessionID);
                }
            } else {
                if (this.props.room.isHost) { // are they the host?
                    this.setState({ notifications: [] });
                    //this.getUserGames();
                    this.updateToken(this.props.room.room.reconnectionToken);
                    this.setTags();
                } else {
                    return setTimeout(() => this.props.navigate("/join"));
                }
            }
        }
    }

    hideSentryFeedback() {
        const element = document.getElementById('sentry-feedback');
        element.hidden = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.room.roomOpen === true && prevProps.room.roomOpen === false) {
            this.setRoomListeners();
        } else if (this.props.room.roomOpen === false && prevProps.room.roomOpen === true) {
            if (this.props.room.startingGame !== true) {
                this.playAudio(audio.error.loaded);
                Howler.stop();
                this.setState({ roomError: true, errorMessage: "The room closed unexpectedly, please click below to try again.", });
            }
        }

        if (this.props.room.startingGame === true && prevProps.room.startingGame === false) {
            this.playAudio(audio.newGame.loaded);
        }
    }

    componentWillUnmount() {
        Howler.stop();
    }

    initAudio() {
        //let mutedVal = this.getMuteSetting();
        //mutedVal = mutedVal ? mutedVal == "false" ? false : true : false;
        //this.toggleMute(true, mutedVal);
        this.preloadAudio();
        Howler.volume(0.5);
        this.playAudio(audio.lobbyBg.loaded, true, 0.05);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio) audio.play();
    }

    toggleFullScreen = () => {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    doCreate(QRSessionID) {
        this.setState({ roomError: false, errorMessage: "", roomLoadingTitle: "Creating your room", })
        this.props.createRoom(this.props.auth.user.token, true, QRSessionID).then((res) => {
            if (res.Status === "Error") {
                Howler.stop();
                this.setState({ roomError: true, errorMessage: res.Message, });
                Sentry.captureMessage(`Error creating room: ${res.Message}`);
            } else if (res.Status === "Success") {
                this.setState({ notifications: [] });
                this.getUserGames();
            }
        });
    }

    doReconnect(token) {
        this.setState({ roomError: false, errorMessage: "", roomLoadingTitle: "Reconnecting to your room", })
        setTimeout(() => {
            this.props.reconnectToRoom(token).then((res) => {
                if (res.Status === "Error") {
                    Howler.stop();
                    this.setState({ roomError: true, errorMessage: res.Message, });
                } else if (res.Status === "Success") {
                    this.setState({ notifications: [] });
                    this.getUserGames();
                }
            });
        }, 1500);

    }

    getUserGames() {
        this.setState({ loadingGames: true });
        AuthService.getGames(this.props.auth.user.email).then((response) => {
            this.setState({ userGames: response.games, loadingGames: false, userAddons: response.addons });
            let gameIds = response.games.map(x => x.code);
            let addonsIds = response.addons.map(x => x.code);
            this.props.room.room.send("update_available_games", { games: gameIds, addons: addonsIds, });
        }).catch((err) => {
            console.error("Error getting user games: ", err);
        });
    }

    setRoomListeners() {
        this.updateToken(this.props.room.room.reconnectionToken);
        this.setTags();
        this.props.room.room.onMessage("player_joined", (message) => {
            console.log("player_joined", "received on", this.props.room.room.name, message);
            this.playAudio(audio.joined.loaded);
            this.doNotification(`${message.player.name} has joined!`);
        });
        this.props.room.room.onMessage("player_leave", (message) => {
            console.log("player_leave", "received on", this.props.room.room.name, message);
            this.playAudio(audio.disconnected.loaded);
            this.doNotification(`${message.player.name} has left!`);
        });
        this.props.room.room.onMessage("refresh_lobby_games", (message) => {
            console.log("refresh_lobby_games", "received on", this.props.room.room.name);
            this.getUserGames();
        });
    }

    doNotification(text) {
        this.setState((prevState) => {
            return { notifications: [...prevState.notifications, text] }
        });
        setTimeout(() => {
            this.setState((prevState) => {
                prevState.notifications.shift();
                return { notifications: prevState.notifications }
            });
        }, 5000);
    }

    getOwnedGames() {
        let userGames = [...this.state.userGames];
        let ownedGameList = [];
        //console.log("user Games", userGames);

        GameList.forEach((x) => {
            if (userGames.findIndex((y) => y.code === x.gameId) > -1) {
                ownedGameList.push(x);
            }
        });
        let i = 1;
        return ownedGameList.length === 0 ?
            <h3 className={styles.noGamesText}>Looks like you dont own any games yet,<br/> please navigate to the shop on your mobile device.</h3>
            :<ul className={styles.games}>
                {ownedGameList.map(g => {
                    const gameVotes = this.props.room.players.filter((player) => player.gameVote === g.gameId).length;
                    return <li className={styles.game} key={g.gameId}>
                        <Game game={g} focus={i === 1} tabIndex={i++} key={g.gameId} fromLobby={true} loadGameMenu={this.loadGameMenu} gameVotes={gameVotes} addons={this.state.userAddons} />
                    </li>
                })}
            </ul>
    }

    isDev() { return !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === 'development' }

    getUnownedGames() {
        let userGames = [...this.state.userGames];
        let unownedGameList = [];

        GameList.filter(g => g.enabled || this.isDev()).forEach((x) => {
            if (userGames.findIndex((y) => y.code === x.gameId) <= -1) {
                unownedGameList.push(x);
            }
        });

        return <ul className={styles.games}>
            {unownedGameList.map(g =>
                <li className={styles.game} key={g.gameId}>
                    <Game game={g} key={g.gameId} isRoku={true} />
                </li>)}
        </ul>;
    }

    loadGameMenu = (game) => {
        this.playAudio(audio.hoverOrClick.loaded);
        this.setState({ selectedGame: game });
    }

    closeGameMenu = () => {
        this.playAudio(audio.hoverOrClick.loaded);
        this.setState({ selectedGame: null });
    }

    closeAndLeave = () => {
        if (this.props.room.roomOpen) {
            this.props.room.room.leave(true);
        }
        this.playAudio(audio.hoverOrClick.loaded);
        this.props.navigate("/");
    }

    acceptSmallScreen = () => {
        this.playAudio(audio.hoverOrClick.loaded);
        this.setState({ acceptedScreenSizeWarning: true });
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    setTags() {
        const queryParams = new URLSearchParams(window.location.search);

        const token = queryParams.get("token");
        Sentry.setTag('isHost', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    mute = () => {
        this.setState({ muted: !this.state.muted });
        Howler.mute(!this.state.muted);
    }

    onAuth = (QRSessionID) => {
        console.log("Logged in", this.props.auth.isLoggedIn);
        this.createOrReconnectRoom(QRSessionID)
    }

    getUserFirendlyRoomError = () => {
        let error = RoomErrorCodes[this.props.room.errorCode];
        console.log("error code:", this.props.room.errorCode);
        if (!error) error = RoomErrorCodes.default;   
        return error.displayMessage;
    }

    render() {
        return (
            <React.Fragment>
                <div className={`${styles.gameLobbyBackground} d-none d-md-block ${styles.roku}`}>

                    {<div>
                        <RokuWelcome onLogin={this.onAuth} />
                    </div>}
                    <div className={styles.gameLobby}>
                        {
                            this.props.room.roomOpen ?
                                <React.Fragment>
                                    {/*<div className={styles.settingButtons}>*/}
                                    {/*    */}{/*{fullscreenAvailable && <button className={styles.muteButton} onClick={this.toggleFullScreen} >*/}
                                    {/*    */}{/*    <img src={fullscreenButton} alt="fullscreen-button" />*/}
                                    {/*    */}{/*</button>}*/}
                                    {/*    <button className={styles.muteButton} onClick={this.mute} >*/}
                                    {/*        <img src={this.state.muted ? mutedButton : muteButton} alt="mute-button" />*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                    <div className={styles.notificationsContainer}>
                                        {
                                            this.state.notifications.map((notification) => {
                                                return <div className={styles.notification}>{notification}</div>
                                            })
                                        }
                                    </div>

                                    {
                                        this.state.loadingGames ?
                                            <div className={styles.loadingBox}>
                                                <Spinner animation="border" />
                                                <h2>Loading your games</h2>
                                            </div>
                                            :
                                            <Container fluid>
                                                <h2 className={`text-center ${styles.lobbyTitle}`}>SELECT A GAME FROM THE VIP DEVICE</h2>
                                                {
                                                    this.getOwnedGames()
                                                }

                                                {
                                                    this.state.userGames.length < GameList.length &&
                                                    <h2 className={styles.separator}>Available Games</h2>
                                                }
                                                {
                                                    this.getUnownedGames()
                                                }
                                            </Container>
                                    }
                                </React.Fragment>
                                :
                                this.state.roomError ?
                                    <div className={styles.pageProblem} >
                                        {/*<h2>{this.state.errorMessage}</h2>*/}
                                        <h2>{this.getUserFirendlyRoomError()} <br/> Click below to rejoin the fun!</h2>
                                        <button className={styles.button} onClick={() => this.doCreate()}>Retry</button>
                                    </div>
                                    :
                                    <div className={styles.loadingBox}>
                                        <h2>{this.state.roomLoadingTitle}</h2>
                                        <Spinner animation="border" />
                                    </div>
                        }

                    </div>

                </div>
                {!this.state.acceptedScreenSizeWarning && <ErrorModal
                    title={"Screen Size Warning"}
                    styles={`${styles.screenModel} d-flex`}
                    message={"Your screen looks too small to host Game City... Please try hosting on a larger screen, like a tablet, TV or laptop and ensure it has an aspect ratio of 4:3 or wider. If you still wish to continue you will likely encounter visual issues!"}
                    callback={this.acceptSmallScreen}
                    callbackText={"Continue"}
                    //callback2={this.closeAndLeave}
                    //callbackText2={"Back"}
                />}
                {/*<div className={`${styles.screenOverlay} d-flex d-sm-flex d-md-none`} >*/}
                {/*    <div className={styles.screenOverlayBG}></div>*/}
                {/*    <FormPage modal={true}>*/}
                {/*        <h2 className={formStyles.formTitle}>Screen Size Warning</h2>*/}
                {/*        <p>Your screen looks too small to host Game City... Please try hosting on a larger screen, like a tablet, TV or laptop.</p>*/}
                {/*        <button onClick={() => this.closeAndLeave()} className={`${styles.button} ${styles.alternate}`}>Back</button>*/}
                {/*    </FormPage>*/}
                {/*</div>*/}

                {
                    this.state.selectedGame &&
                    <GameMenu game={this.state.selectedGame} closeGameMenu={this.closeGameMenu} />
                }
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        room: state.room,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createRoom: (token, isRoku, QRSessionID) => {
            return dispatch(createRoom(token, isRoku, QRSessionID))
        },
        reconnectToRoom: (token) => {
            return dispatch(reconnectToRoom(token))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Roku));
