import React, { Component } from 'react';
import { withRouter } from '../withRouter';
import { getCookie, setCookie } from 'helpers/cookies'
import { connect, } from 'react-redux';
import Form from 'react-bootstrap/Form';
import promotionService from 'services/promotion.service';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import styles from 'components/CRMModal/CRMModalStyles.module.scss';
import tickImg from 'images/Promotions/Blue Tick.png'
import envelopeImg from 'images/Promotions/Email Guy.png'
import cross from 'images/icons/Cross.png'

class CRMModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            enabled: true,
            show: false,
        };
    }

    componentDidMount() {
        const acepted = getCookie("crm-accepted");
        const dontShow = getCookie("crm-dont-show");
        if (acepted) setCookie("crm-accepted", true, 400); //keeps the cookie alive (provided they return in 400 days)
        const shouldShow = !acepted && !dontShow && !this.props.auth.isLoggedIn
        this.setState({ acepted: acepted, dontShow: dontShow, enabled: shouldShow, show: shouldShow });
    }

    onChangeEmail = (e) => {
        const email = e.target.value;
        this.setState({ email });
    };

    onChangeDOB = (e) => {
        const DOB = e.target.value;
        this.setState({ DOB });
    }

    register = (e) => {
        if (!this.state.show) return;
        e.preventDefault();
        setCookie("crm-accepted", true, 400);
        this.setState({ show: false });
        setTimeout(() => {
            this.setState({ enabled: false });
        }, 500);
        promotionService.submitPromotionEntry(process.env.REACT_APP_NEWSLETTER_PROMO_ID, this.state.email, this.props.pricing.countryCode, this.state.DOB, ["newsletter"]);
    }

    close = () => {
        if (!this.state.show) return;
        setCookie("crm-dont-show", true, 7)
        this.setState({ show: false });
        setTimeout(() => {
            this.setState({ enabled: false });
        }, 500);
    }

    dontShow = () => {
        setCookie("crm-dont-show", true, 30)
    }

    render() {
        return <React.Fragment>
            {this.state.enabled && window.location.pathname !== "roku" &&
                <div className={`${styles.screenOverlay} ${this.state.show && styles.show} ${this.props.styles}`} >
                    <div className={styles.screenOverlayBG}></div>
                    <div className={styles.modalContainer} >
                        <Form onSubmit={this.register} className={styles.modal} >
                            <button className={styles.closeButton} onClick={this.register} >
                                <img src={cross} alt="close-button" />
                            </button>
                            <h1 className={styles.title} > JOIN THE NEWSLETTER</h1>
                            <div className={styles.columbs} >
                                <div className={styles.columb}>
                                    <p className={styles.description} >Join the Game.City Newsletter for FREE today and enjoy exclusive game tips, the latest news, and special discounts!</p>
                                </div>
                                <div className={styles.columb}>
                                    <div className={styles.calloutWrapper} >
                                        <img src={tickImg} className={styles.tick} alt="tick" ></img>
                                        <p className={styles.callouts}>10% OFF YOUR FIRST ORDER</p>
                                    </div>
                                    <div className={styles.calloutWrapper} >
                                        <img src={tickImg} className={styles.tick} alt="tick" ></img>
                                        <p className={styles.callouts}>COMPETITIONS AND GIVEAWAYS</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.signUp} >
                                {/*<FloatingLabel controlId="floatingEmail" label="Email">*/}
                                {/*    <Form.Control className={styles.email} type="email" placeholder="Enter email" required onChange={this.onChangeEmail} />*/}
                                {/*</FloatingLabel>*/}
                                <Form.Control className={styles.email} type="email" placeholder="Enter email" required onChange={this.onChangeEmail} />
                                <FloatingLabel controlId="floatingDate" label="Date of birth (optional)">
                                    <Form.Control className={styles.email} type="date" placeholder="Enter date of birth (optional)" onChange={this.onChangeDOB} />
                                </FloatingLabel>
                                <div className={styles.buttonWrapper} >
                                    <button className={`${styles.button}  ${styles.alternate}`} type="submit" >GIVE ME FREE STUFF</button>
                                    <img src={envelopeImg} alt="envelope" />
                                </div>
                            </div>
                            <div className={styles.dontShow} >
                                <a className={`${styles.link}`} onClick={this.close} >I DONT LIKE FREE STUFF</a>
                            </div>
                        </Form>
                    </div>
                </div>
            }
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pricing: state.pricing,
    };
}

export default connect(mapStateToProps)(withRouter(CRMModal));