export const RoomErrorCodes = {
    4001: {
        displayMessage: "Room has been closed due to inactivity."
    },
    4002: {
        displayMessage: "Disconnected due to no host being connected."
    },
    4003: {
        displayMessage: "Disconnected due to host joining the wrong game."
    },
    4004: {
        displayMessage: "Disconnected due to no Host when connection established, force closing room."
    },
    4005: {
        displayMessage: "Disconnected due to no Host when client left, force closing room."
    },
    default: {
        displayMessage: "Oops! Looks like something went wrong."
    }
}